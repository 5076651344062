import React from 'react';
import Dialog from '@mui/material/Dialog';

interface CustomModalProps {
  showModal: boolean;
  maxWidth?: string;
  customClass?: string;
  handleShowModal?: () => void;
  children: React.ReactNode;
}
const CustomModal = ({ showModal, customClass, handleShowModal, children, maxWidth = '100%' }: CustomModalProps) => {
  return (
    <div>
      <Dialog
        open={showModal}
        fullWidth={true}
        style={{ maxWidth: maxWidth, margin: '0 auto' }}
        onClose={handleShowModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={customClass}
      >
        {children}
      </Dialog>
    </div>
  );
};

export default CustomModal;
