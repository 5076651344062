import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './Users.module.scss';
import upload from 'assets/images/upload.svg';
import plus from 'assets/images/plus-circle.svg';
import Card from 'component/card/Card';

const Users = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={` ${styles.user}`}>
      <div className={`dflex justifySpaceBetween ${styles.header}`}>
        <h1>Customers</h1>
        <div className={` flex ${styles.button}`}>
          <button className="button outline">
            <img src={upload} alt="import " />
            Import
          </button>
          <button className="button secondary">
            {' '}
            <img src={plus} alt="customer " /> Add customer
          </button>
        </div>
      </div>

      <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Overview" {...a11yProps(0)} />
        <Tab label="Table" {...a11yProps(1)} />
        <Tab label="List view" {...a11yProps(2)} />
        <Tab label="Segment" {...a11yProps(3)} />
        <Tab label="Custom" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className={` ${styles.row}`}>
          <div className={` ${styles.col}`}>
            <Card />
          </div>
          <div className={` ${styles.col}`}>
            <Card />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Table
      </TabPanel>
      <TabPanel value={value} index={2}>
        List view
      </TabPanel>
      <TabPanel value={value} index={3}>
        Segment
      </TabPanel>
      <TabPanel value={value} index={4}>
        Custom
      </TabPanel>
    </div>
  );
};

export default Users;
