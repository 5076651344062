import React, { ReactNode } from 'react';
import Sidebar from 'component/sidebar/Sidebar';
import styles from './DesignWrapper.module.scss';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import menu from 'assets/images/menu.svg';
import logo from 'assets/images/logo.svg';
// import logoWhite from 'assets/images/logo-white-all.svg';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const DesignWrapper = ({ children }: { children: ReactNode }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div className={`dflex ${styles.wrapper}`}>
      <Sidebar />
      <div className={`dflex ${styles.wrapper__right}`}>
        <div className={` ${styles.menu}`}>
          {(['left'] as const).map((anchor) => (
            <React.Fragment key={'left'}>
              <img src={logo} alt="Swam" className={`img-dark ${styles.logo}`} />
              <img src={logo} alt="Swam" className={`img-normal ${styles.logo}`} />
              <Button onClick={toggleDrawer(anchor, true)}>
                <img src={menu} alt="menu" />
              </Button>
              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                <Sidebar />
              </Drawer>
            </React.Fragment>
          ))}
        </div>
        {children}
      </div>
    </div>
  );
};

export default DesignWrapper;
