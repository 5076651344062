import AuthAndLoadProducts from 'component/authAndLoadProducts/AuthAndLoadProducts';
import CategoriesAndProducts from 'component/categoriesAndProducts/CategoriesAndProducts';
import FileUploadType from 'component/fileUploadType/FileUploadType';
import ShowSuccessMessage from 'component/showMessage/ShowSuccessMessage';
import { SHOW_CASE } from 'enum/enum';
import { ChildFunctionProps } from 'interface/interface';
import ReviewTable from 'component/review/ReviewTable';
import React from 'react';

export const ChildFunction = ({
  showCase,
  handleAuthenticate,
  loading,
  fetchProducts,
  allProducts,
  productPageInfo,
  handleProductOnBoard,
  fetchCollections,
  allCollections,
  setAllProducts,
  setAllCollections,
  handleToggleAll,
  setShowRequirements,
  handleShowModal,
  showRequirements,
  handleFileUploaded,
  files,
  fileFilter,
  showPopper,
  childLoading,
  setTechPackRes,
  techPackRes,
  productAllToggle,
  groups,
  fetchGroups,
  fetchGroupProducts,
  buttonDisabled,
  setValue,
  value,
  groupDetail,
  handleTabsChange,
  selectedProduct,
  setSelectedProduct,
  handleRemoveProductsFromGroup,
  handleTechPackData,
  sizesMatch,
  fetchCollectionProducts,
  // setButtonDisabled,
  handleTechPackSubmit,
  childButtonDisabled,
  setChildButtonDisabled,
  collectionLoading,
}: // groupProducts,
ChildFunctionProps) => {
  switch (showCase) {
    case SHOW_CASE.AUTH: {
      return <AuthAndLoadProducts showCase={showCase} handleAuthenticate={handleAuthenticate} loading={loading} />;
    }
    case SHOW_CASE.LOAD_PRODUCT: {
      return <AuthAndLoadProducts showCase={showCase} loading={loading} fetchProducts={fetchProducts} />;
    }
    case SHOW_CASE.ALL_PRODUCTS: {
      return (
        <CategoriesAndProducts
          allProducts={allProducts}
          productPageInfo={productPageInfo}
          fetchProducts={fetchProducts}
          loading={loading}
          handleProductOnBoard={handleProductOnBoard}
          fetchCollections={fetchCollections}
          allCollections={allCollections}
          setAllProducts={setAllProducts}
          setAllCollections={setAllCollections}
          handleToggleAll={handleToggleAll}
          productAllToggle={productAllToggle}
          handleShowModal={handleShowModal}
          groups={groups}
          fetchGroups={fetchGroups}
          fetchGroupProducts={fetchGroupProducts}
          buttonDisabled={buttonDisabled}
          value={value}
          setValue={setValue}
          groupDetail={groupDetail}
          handleTabsChange={handleTabsChange}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          handleRemoveProductsFromGroup={handleRemoveProductsFromGroup}
          childLoading={childLoading}
          fetchCollectionProducts={fetchCollectionProducts}
          childButtonDisabled={childButtonDisabled}
          collectionLoading={collectionLoading}
        />
      );
    }
    case SHOW_CASE.FILE_UPLOAD_TYPE: {
      return (
        <FileUploadType
          setShowRequirements={setShowRequirements}
          showPopper={showPopper}
          handleShowModal={handleShowModal}
          showRequirements={showRequirements}
          handleFileUploaded={handleFileUploaded}
          files={files}
          fileFilter={fileFilter}
        />
      );
    }
    case SHOW_CASE.REVIEW_TABLE: {
      return (
        <ReviewTable
          techPackRes={techPackRes}
          setTechPackRes={setTechPackRes}
          childLoading={childLoading}
          groups={groups}
          handleTechPackData={handleTechPackData}
          sizesMatch={sizesMatch}
          // setButtonDisabled={setButtonDisabled}
          handleTechPackSubmit={handleTechPackSubmit}
          loading={loading}
          handleShowModal={handleShowModal}
          techPackButtonDisabled={childButtonDisabled}
          setTechPackButtonDisabled={setChildButtonDisabled}
        />
      );
    }
    default: {
      return (
        <div className="dflex alignCenter justifyCenter successMeasurement">
          <ShowSuccessMessage fileUploadSuccess={true} />
        </div>
      );
    }
  }
};
