export const {
  REACT_APP_BASE_URL,
  REACT_APP_POSTHOG_LIVE_PROJECT_ID,
  REACT_APP_POSTHOG_PERSONAL_API_KEY,
  REACT_APP_POSTHOG_STAGING_PROJECT_ID,
  REACT_APP_POSTHOG_URL,
  REACT_APP_DASHBOARD_BASE_URL,
  REACT_APP_PRODUCTS_API_KEY,
  REACT_APP_CUSTOM_URL,
  REACT_APP_CUSTOM_SITE_PASSWORD,
  REACT_APP_PUBLIC_MODEL_VIEW_URL,
} = process.env;

// export const REACT_APP_BASE_URL = 'https://staging.api.dashboard.getswan.co';
// export const REACT_APP_DASHBOARD_BASE_URL = 'https://api.dashboard.getswan.co';
// export const REACT_APP_PRODUCTS_API_KEY = '12198b22-9d92-44f1-8220-0868a41dc089';
// export const REACT_APP_POSTHOG_PROJECT_API_KEY = 'phc_S1Z71u2vBqoH9LhrpckEJoroobYhvHzM1fnjIqse41A';
// export const REACT_APP_POSTHOG_PERSONAL_API_KEY = 'phx_6UVMT0CPtiXR8f71ZTmiglhcfDEWOrj4LqQOdAMjH0p';
// export const REACT_APP_POSTHOG_LIVE_PROJECT_ID = '34860';
// export const REACT_APP_POSTHOG_STAGING_PROJECT_ID = '30057';
// export const REACT_APP_POSTHOG_URL = 'https://app.posthog.com';

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  NOT_MATCH: '*',
  SIGNUP: '/signup',
  FORGET_PASSWORD: '/forget-password',
  CUSTOM: '/custom',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  TASKS: '/tasks',
  REPORTING: '/reporting',
  USERS: '/users',
  SUPPORT: '/support',
  SETTINGS: '/settings',
  ONBOARDING: '/onboarding',
  MEASUREMENTS: '/measurements',
  ANALYTICS: '/analytics',
  REFERRALS: '/referrals',
  USER_VERIFY: '/user-verify',
  CUSTOMERS_LIST: '/customers-list',
  RESOURCES: '/resources',
  KPI_ANALYTICS: '/kpi-analytics',
  CUSTOM_DETAILS: 'details/:id',
};

export const API_END_POINTS = {
  VERIFY_USER_ENDPOINT: '/api/verify',
  SHOP_API: '/api/shop',
  POSTHOG_PROJECT: '/api/projects',
  DASHBOARD_METRICS: '/dashboard/metrics',
  AUTHORIZE_USER: '/auth/link/authorize',
  AUTH_CALLBACK: '/auth/link/callback',
  CUSTOMER: '/customer',
  PRODUCTS: '/products',
  ONBOARD_PRODUCT: '/products/onboard',
  COLLECTIONS: '/collections',
  UPLOAD_URL: '/files/url',
  TECHPACK: '/techpack',
  INTERNAL_AUTH: '/internal/auth',
  NOTES: '/notes',
  GROUPS: '/groups',
  SIZES: '/sizes',
  ONBOARD: '/onboard',
  REFERRAL: '/referrals',
  CUSTOM_FIT: '/customfit',
  FILES_LIST: '/files/list',
  DELETE_FILE: '/files',
  CUSTOM_FIT_EXPORT: '/customfit/export',
  MEASUREMENTS: '/measurements',
  PRODUCTS_INITIALIZE: '/products/initialize',
};
export const excludeEmails = [
  'brenhogan@gmail.com',
  'eoin@getswan.co',
  'adrianocmail@gmail.com',
  'caroline@reneruizcollection.com',
  'atabak@syze.ai',
  'support@intimatebrides.com',
  'charlottepolefit@yahoo.com',
  'neal@getswan.co',
];
export const emailRegex = /.*@mailinator\.com/;
export const secondEmailRegex = /\w+@[\w.]+\b\.ie\b/;
export const SOMETHING_WENT_WRONG = 'something went wrong';

export const RowOptions = [
  {
    name: 'head',
    id: 'b9e1c9a5-5fda-420c-871f-3a839cc33f10',
    selected: false,
  },
  {
    name: 'shoulder',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
    selected: false,
  },
  {
    name: 'acrossBackShoulder',
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    selected: false,
  },
  {
    name: 'neckBase',
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e1',
    selected: false,
  },
  {
    name: 'neckCirc',
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b',
    selected: false,
  },
  {
    name: 'bust',
    id: 'ee2b2073-690e-40ac-a839-46ba7d2c70e2',
    selected: false,
  },
  {
    name: 'torso',
    id: '0fe0de7e-7d52-485c-8659-be6fba71feab',
    selected: false,
  },
  {
    name: 'underBust',
    id: 'a70d99bd-2bfa-4fd3-bd10-3a9931d1e7f6',
    selected: false,
  },
  {
    name: 'armLength',
    id: 'c76ec6e0-5e8d-4cd6-bbd7-84f92bacd79a',
    selected: false,
  },
  {
    name: 'bicep',
    id: 'bf3f996c-3993-4891-9a99-a10631bfe6ce',
    selected: false,
  },
  {
    name: 'elbow',
    id: 'f0a671a6-9039-4c77-9e3f-a8e53afa3859',
    selected: false,
  },
  {
    name: 'forearm',
    id: 'a1b25dec-3e22-47fd-9f09-e17c6b19369b',
    selected: false,
  },
  {
    name: 'wrist',
    id: 'cc28e459-0247-4a0c-9b53-8ab7bf8a8736',
    selected: false,
  },
  {
    name: 'napeToWaist',
    id: 'b52fa8e0-1706-4503-9c7c-ff2ad052de4b',
    selected: false,
  },
  {
    name: 'waist',
    id: 'cdd9d799-1e2d-4683-b4f1-75acf619d214',
    selected: false,
  },
  {
    name: 'waistBellybutton',
    id: 'cdd9d799-1e2d-4683-b4f1-75acf619d2144434',
    selected: false,
  },
  {
    name: 'highHip',
    id: 'cdd9d799-1e2d-4683-b4f1-75acf619d3234214',
    selected: false,
  },
  {
    name: 'hip',
    id: 'cdd9d799-1e21hd-4683-b4f1-75acf619d214',
    selected: false,
  },
  {
    name: 'inseam',
    id: 'cdd9d799-1e2d-4683-b4f1ohj-75acf619d214',
    selected: false,
  },
  {
    name: 'inseamToAnkle',
    id: 'e292ddce-7899-4d1453a-b057-b514d58d94a4',
    selected: false,
  },
  {
    name: 'knee',
    id: 'e292ddce-789jth9-4d3a-b057-b514d58d94a4',
    selected: false,
  },
  {
    name: 'seat',
    id: 'e292ddce-7899-4d3a-b057-b514d58d94a4',
    selected: false,
  },
  {
    name: 'thigh',
    id: '4aea97b1-838d-46de-af31-a276a0626aac',
    selected: false,
  },
  {
    name: 'calf',
    id: 'cdd9d799-1e2d-4683-b4f1-75acf619d21490',
    selected: false,
  },
  {
    name: 'ankle',
    id: 'a1b83077-7433-463b-9488-82906fdff672',
    selected: false,
  },
];

export const OnBoardToolTipText = 'Add tech pack to onboard';
export const FULFILLED = 'fulfilled';
export const HeadCells = [
  { label: 'All products', id: 0 },
  { label: 'Group name', id: 1 },
  // { label: 'Product link', id: 2 },
  // { label: 'Shopify ID', id: 3 },
  // { label: 'Status', id: 2 },
  { label: 'Onboard', id: 2 },
];
export const scanUrl = 'https://swan-custom.myshopify.com/';
export const groupCategory = ['jeans', 'jacket', 'shirt', 'dress', 'trousers'];
export const groupDivision = ['Mens', 'Womens'];
export const PRODUCTS_DO_NOT_HAVE_SAME_SIZE = 'Products do not have the same sizes';
export const NONE = 'none';

export const ResourcesData = [
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: 'b9e1c9a5-5fda-420c-871f-3a839cc33f101',
    header: 'Onboard new products',
    description: 'How do you create compelling presentations that wow your colleagues and impress your managers?',
  },
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d22',
    header: "Track Swan's ROI",
    description: "Linear helps streamline software projects, sprints, tasks, and bug tracking. Here's how to get started",
  },
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a56',
    header: 'Add a banner',
    description: 'Mental models are simple expressions of complex processes or relationships.',
  },
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e167',
    header: 'Share Swan with your customers',
    description: 'Introduction to Wireframing and its Principles. Learn from the best in the industry.',
  },
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b89',
    header: 'Refer a new brand',
    description: 'Collaboration can make our teams stronger, and our individual designs better',
  },
  {
    title: 'Fitting Rooms: A ritual to perpetuate',
    id: 'ee2b2073-690e-40ac-a839-46ba7d2c70e287',
    header: 'Leave a 5-star review',
    description: "Starting a community doesn't need to be complicated, but how do you get started?",
  },
];

export const hasCustomAddOnFlag = 'hasCustomAddon';
