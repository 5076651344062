import React, { Suspense } from 'react';
import '@google/model-viewer/lib/model-viewer';
import CircularLoader from 'component/CircularLoader';
// import abc from '../assets/test_tposed_128.glb';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerJSX & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}
interface ModelViewerJSX {
  src: string;
  poster?: string;
  iosSrc?: string;
  seamlessPoster?: boolean;
  autoplay?: boolean;
  environmentImage?: string;
  exposure?: string;
  interactionPromptThreshold?: string;
  shadowIntensity?: string;
  ar?: boolean;
  arModes?: string;
  autoRotate?: boolean;
  cameraControls?: boolean;
  cameraOrbit?: string;
  alt?: string;
  sx?: any;
}
const Model = ({ src }: { src: string }) => {
  return (
    <Suspense fallback={<CircularLoader size={20} />}>
      <model-viewer
        id="first"
        src={src}
        // ios-src={item.iosSrc}
        seamless-poster
        environment-image="neutral"
        exposure="1.0"
        interaction-prompt-threshold="0"
        shadow-intensity="1"
        ar
        autoplay
        ar-modes="webxr scene-viewer quick-look"
        auto-rotate
        camera-controls
        camera-orbit="0deg 90deg 0deg 8.37364m"
        alt="3D model"
        style={{
          width: '100%',
          height: '470px',
          maxWidth: '537px',
          margin: '0 auto',
        }}
      >
        {' '}
        <div className="progress-bar hide" slot="progress-bar">
          <div className="update-bar"></div>
        </div>
      </model-viewer>
    </Suspense>
  );
};

export default Model;
