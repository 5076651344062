import React from 'react';
import styles from './SuccessPopper.module.scss';
import check from '../../assets/images/check-circle.svg';
export interface SuccessPopperProps {
  show: boolean;
}

const SuccessPopper = ({ show }: SuccessPopperProps) => {
  return (
    <div className={`${styles.popper} flex ${show && styles.active}`}>
      <figure>
        <img src={check} alt="check" />
      </figure>
      <figcaption>
        <h2>All measurements uploaded</h2>
        <p>All measurement files have now been sent for parsing. Keep an eye on your email for updates. </p>
      </figcaption>
    </div>
  );
};

export default SuccessPopper;
