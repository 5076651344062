import React from 'react';
import ReactApexChart from 'react-apexcharts';

// import Chart from 'react-apexcharts';

const ApexChartArea = () => {
  const options = {
    chart: {
      sparkline: {
        enabled: true,
      },
      toolbar: { show: false },
      //   legend: {
      //     show: false,
      //   },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: ['#12B76A'],
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ['#12B76A'],
    },
    tooltip: { enabled: false },
    grid: {
      show: false, // you can either change hear to disable all grids
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      tickAmount: 0,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  } as any;
  const series = [
    {
      name: 'user',
      data: [0, 30, 15, 60],
    },
  ];

  return <ReactApexChart height={60} options={options} series={series} type="area" />;
};
export default ApexChartArea;
