import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastInterface } from 'interface/interface';
import { ToastTypes } from 'enum/enum';

export const ToastContext = React.createContext<ToastInterface | null>(null);

export default function ToastContextProvider({ children }: { children: React.ReactNode }) {
  const callToast = (type: ToastTypes, msg: string) => {
    if (!msg) return;
    switch (type) {
      case ToastTypes.SUCCESS: {
        return toast.success(msg);
      }
      case ToastTypes.ERROR: {
        return toast.error(msg);
      }
      case ToastTypes.INFO: {
        return toast.info(msg);
      }
      default: {
        return toast(msg);
      }
    }
  };

  return (
    <ToastContext.Provider value={{ callToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
}
