// import { Checkbox, FormControlLabel } from '@mui/material';
import CustomButton from 'component/CustomButton';
import Input from 'component/input/Input';
import React, { useContext, useState } from 'react';
import styles from './Referrals.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
//import help from 'assets/images/help-circle.svg';
import { handleErrorMessage, isValidEmail, modifyStoreUrl } from 'utils/utils';
import { submitNewReferral } from 'services/referralService';
import { ToastTypes } from 'enum/enum';
import { ToastContext } from 'context/Toast';
import { ToastInterface } from 'interface/interface';

interface ReferralsInputs {
  // brandYes: boolean;
  // brandNo: boolean;
  brandDetails: string;
  brandName: string;
  brandDomain: string;
  message: string;
  email: string;
  name: string;
}
const defaultValues = {
  // brandYes: true,
  // brandNo: false,
  message: '',
  brandDomain: '',
  email: '',
  name: '',
  brandName: '',
  // brandDomain: '',
};
const Referrals = () => {
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors }, //  getValues,
    setError,
    // setValue,
    reset,
    watch,
  } = useForm<ReferralsInputs>({
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<ReferralsInputs> = async (data) => {
    const { name, email, message, brandDomain, brandName } = data;

    // if (!brandDetails.trim()) {
    //   setError('brandDetails', { type: 'custom', message: 'Please enter a valid details' });
    // }
    if (!name.trim()) {
      setError('name', { type: 'custom', message: 'Please enter a valid name' });
    } else if (!isValidEmail(email.trim())) {
      setError('email', { type: 'custom', message: 'Please enter a valid email' });
    } else if (!brandName.trim()) {
      setError('brandName', { type: 'custom', message: 'Please enter a valid brand name' });
    } else if (!brandDomain.trim()) {
      setError('brandName', { type: 'custom', message: 'Please enter a valid store url' });
    } else if (!message.trim()) {
      setError('message', { type: 'custom', message: 'Please enter a valid message' });
    } else {
      setLoading(true);
      try {
        const modData = {
          inviteMessage: message,
          brandContactName: name,
          brandContactEmail: email,
          brandName: brandName,
          brandStoreUrl: modifyStoreUrl(brandDomain),
        };
        await submitNewReferral(modData);
        callToast(ToastTypes.SUCCESS, 'Successfully submitted');
        reset(defaultValues);
      } catch (error) {
        callToast(ToastTypes.ERROR, handleErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={` ${styles.referral}`}>
      <div className={` ${styles.referral__header}`}>
        <h4>Give a month, get a month. </h4>
        <p>Share Swan with other Shopify stores. For every brand you refer that goes live, you get a month free!</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className={` ${styles.referral__form}`}>
          <div className={` ${styles.label}`}>
            <h5>Shopify</h5>
            <p>Is this brand based shopify?</p>
          </div>
          <div className={` ${styles.fields}`}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  // onChange={(e) => {
                  //   setValue('brandYes', e.target.checked);
                  //   setValue('brandNo', !e.target.checked);
                  // }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={false}
                  onChange={() => {
                    callToast(ToastTypes.ERROR, `Currently we only support shopify stores`);
                  }}
                />
              }
              label="No"
            />
          </div>
        </div> */}
        <div className={` ${styles.boxShadow}`}>
          <h3>Brand</h3>
          <div className={` ${styles.referral__form}`}>
            <div className={` ${styles.fields}`}>
              <div>
                <Input
                  type="text"
                  placeholder="Enter brand name"
                  inputName="brandName"
                  register={register}
                  errorMessage={errors.brandName ? (errors.brandName.message ? errors.brandName.message : 'This field is required') : ''}
                />
                <div className={` ${styles.fieldInput}`}>
                  {/* <label>
                  <input type="text" {...register('brandDomain', { required: true })} placeholder="myshopify.com" />
                </label> */}
                  <div className={` ${styles.output}`}>
                    <input type="text" {...register('brandDomain', { required: true })} placeholder="Enter store url" />
                  </div>
                  {/* <label>myshopify.com</label> */}
                </div>
                {errors.brandDomain && <div className={` ${styles.outputError}`}>{errors?.brandName?.message || 'This field is required'}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className={` ${styles.boxShadow}`}>
          <h3>Contact</h3>
          <div className={` ${styles.referral__form}`}>
            {/* <div className={` ${styles.label}`}>
            <h5>Contact details </h5>
          </div> */}
            <div className={` ${styles.fields}`}>
              <div>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  inputName="name"
                  register={register}
                  errorMessage={errors.name ? (errors.name.message ? errors.name.message : 'This field is required') : ''}
                />
                <Input
                  type="text"
                  placeholder="Enter Email"
                  inputName="email"
                  register={register}
                  //afterIcon={help}
                  errorMessage={errors.email ? (errors.email.message ? errors.email.message : 'This field is required') : ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={` ${styles.boxShadow}`}>
          <h3 className={` ${styles.spaceBottom}`}>Share your experience with Swan</h3>
          <div className={` ${styles.referral__form} ${styles.borderTop} ${styles.noSpace}`}>
            {/* <div className={` ${styles.label}`}>
            <h5>Share message with invite</h5>
            <p>A quick snapshot of your experience with Swan.</p>
          </div> */}
            <div className={` ${styles.fields}`}>
              <div>
                <Input
                  type="textarea"
                  placeholder="Enter message"
                  inputName="message"
                  register={register}
                  textLimit={200 - watch('message').length}
                  errorMessage={errors.message ? (errors.message.message ? errors.message.message : 'This field is required') : ''}
                />
                <div className={` ${styles.charLeft}`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={` flex ${styles.referral__form} ${styles.borderTop} ${styles.submit}`}>
          <CustomButton type="submit" buttonText="Submit" className="button" loading={loading} disabled={loading} />
        </div>
      </form>
    </div>
  );
};

export default Referrals;
