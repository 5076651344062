import { Checkbox, Switch, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
// import ArrowDownIcon from 'assets/images/arrow-down-alt.svg';
// import ArrowUpIcon from 'assets/images/arrow-up-alt.svg';

import { SortType } from 'enum/enum';
interface EnhancedTableProps {
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  headCells: any[];
  allToggle?: boolean;
  numSelected?: number;
  handleToggleAll?: (a: boolean) => void;
  showCheckBox?: boolean;
  showToggle?: boolean;
  buttonDisabled?: boolean;
  withoutCheckBox?: boolean;
  sortType?: SortType;
  sortColumns?: (t: SortType) => void;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    headCells,
    numSelected,
    rowCount,
    allToggle,
    handleToggleAll,
    showCheckBox = true,
    showToggle = true,
    buttonDisabled = false,
    withoutCheckBox = false,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          withoutCheckBox ? (
            <React.Fragment key={headCell?.id}>
              <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                <div className={`flex alignCenter gap5 ${headCell?.center ? `justifyCenter` : ``} `}>{headCell.label}</div>
                {/* {customGroup ? (
                  <div className="flex alignCenter gap5">{headCell.label}</div>
                ) : (
                  <div role="cursor" className="flex alignCenter gap5" onClick={() => sortColumns && sortType && sortColumns(sortType)}>
                    {headCell.label}
                    <img role="cursor" src={sortType === SortType.ASCENDING ? ArrowDownIcon : ArrowUpIcon} alt="Arrow" />
                  </div>
                )} */}
              </TableCell>
            </React.Fragment>
          ) : (
            <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
              <div className="flex alignCenter ">
                {headCell.id === 0 && showCheckBox && <Checkbox color="primary" checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} />}
                {headCell.label}
                {headCell.id === 2 && showToggle && (
                  <div className={'is-allSwitch'}>
                    <Switch checked={allToggle} disabled={!rowCount || buttonDisabled} onChange={(e) => handleToggleAll && handleToggleAll(e.target.checked)} />
                  </div>
                )}
                {/* <img src={ArrowDownIcon} alt="Arrow" /> */}
              </div>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
