import React from 'react';
import { CircularProgress } from '@mui/material';

const CircularLoader = ({ size = 40, height = true }: { size?: number; height?: boolean }) => {
  return (
    <div className={`flex alignCenter justifyCenter ${height ? 'h-100' : ''} loader`}>
      <CircularProgress size={size} />
    </div>
  );
};

export default CircularLoader;
