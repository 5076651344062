import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styles from './CountryUsersProgress.module.scss';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { CountryUsersProgressProps } from 'interface/interface';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#5932F3' : '#EAECF0',
  },
}));

const CountryUsersProgress = ({ value, countryName, countryCode }: CountryUsersProgressProps) => {
  return value ? (
    <div className={`${styles.main}`}>
      <div className={`flex ${styles.countryFlag}`}>
        <figure>
          <span className={`fi fi-${countryCode}`}></span> <span className={`fi fi-${countryCode} fis`}></span>
        </figure>
        <p>{countryName}</p>
      </div>
      <div className={`flex  alignCenter ${styles.progressBarMain}`}>
        <div className={`${styles.progressBar}`}>
          <BorderLinearProgress variant="determinate" value={value} />
        </div>
        <div className={`${styles.progressBarValue}`}>{value}%</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CountryUsersProgress;
