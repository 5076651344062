import React from 'react';
//  import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import RecentActorsIcon from '@mui/icons-material/RecentActors';
import styles from './List.module.scss';
// import { AuthContextInterface } from 'interface/interface';
// import { AuthContext } from 'context/AuthContext';
import ApexChart from 'component/ApexChart';
import Card from 'component/card/Card';
import MapCompo from 'component/mapCompo/MapCompo';
import { createCountryData, formatNumber, getPercentageArray } from 'utils/utils';
import CountryUsersProgress from 'component/countryUsersProgress/CountryUsersProgress';
// import ProductProgressGraph from 'component/productsProgressGraph/ProductProgressGraph';
import { ListChildProps } from 'interface/interface';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';

export const ListChildNew = ({ usersList, datePickerValue, noOfSizingSuggestions, productDetails }: ListChildProps) => {
  //   const { userDetails } = useContext(AuthContext) as AuthContextInterface;
  const countryData = createCountryData(usersList);
  const { total, percentageArray } = getPercentageArray(countryData);
  return (
    <>
      <div className={`${styles.mapMain} dflex`}>
        <header className="dflex justifySpaceBetween alignCenter">
          <h2>Active users right now</h2>
          {/* <span>Real-time report</span> */}
        </header>
        <div className={`${styles.map}`}>
          <MapCompo countryData={countryData} />
        </div>
        <div className={`${styles.mapList}`}>
          <h2>{formatNumber(total)}</h2>
          {percentageArray?.map((el) => (
            <CountryUsersProgress key={el?.id} countryName={el?.name} value={el?.percentageVal} countryCode={el?.code?.toLowerCase()} />
          ))}
        </div>
      </div>

      <div className={`${styles.cardList} dflex`}>
        <div className={`${styles.card}`}>
          <Card title={'Total Scans'} value={usersList?.length} />
        </div>
        <div className={`${styles.card}`}>
          <Card title="Sizing Suggestions" value={noOfSizingSuggestions} />
        </div>
        <div className={`${styles.card}`}>
          <Card title="Swan Powered Sales" value={productDetails?.totalSales?.totalOrderCount ?? 0} />
        </div>
      </div>
      <div className={` ${styles.graphBox}`}>
        <header>
          <h2>Daily Scans</h2>
        </header>
        <article className={` ${styles.chart}`}>{usersList.length > 0 ? <ApexChart data={usersList} datePickerValue={datePickerValue} /> : <NoDataPlaceHolder />}</article>
      </div>
      <div className={`dflex ${styles.graphList}`}>
        {/* <div className={` ${styles.leftBox}`}>
          <div className={` ${styles.graphBox}`}>
            <header>
              <h2>Products supported Swan</h2>
              <p>{productDetails?.productsSupported?.percentage ?? 0}% of products on your store are live with Swan</p>
            </header>
            {Object.keys(productDetails)?.length > 0 ? <ProductProgressGraph productsSupported={productDetails?.productsSupported} /> : <NoDataPlaceHolder />}
          </div>
        </div> */}
        {/* <div className={` ${styles.leftBox}`}>
          <div className={` ${styles.graphBox}`}>
            <header>
              <h2>Daily Scans</h2>
            </header>
            <article className={` ${styles.chart}`}>
              {usersList.length > 0 ? <ApexChart usersList={usersList} datePickerValue={datePickerValue} /> : <NoDataPlaceHolder message="" />}
            </article>
          </div>
        </div> */}
        <div className={` ${styles.leftBox}`}>
          <div className={` ${styles.graphBox}`}>
            <header>
              <h2>Recent activity</h2>
            </header>
            <div className={` ${styles.emailMain}`}>
              {usersList?.length > 0 ? (
                <div className={` ${styles.emailList}`}>
                  {usersList?.map((user: any) =>
                    user?.properties?.email ? (
                      <div className={` ${styles.emailList__list}`} key={user?.id}>
                        <p>{user?.properties?.email}</p>
                      </div>
                    ) : (
                      <div className={` ${styles.emailList__list}`} key={user?.id}>
                        <p>Anonymous</p>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <NoDataPlaceHolder message="No data found in the list at the moment" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
