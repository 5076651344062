import { API_END_POINTS } from 'constants/constants';
import { TOKEN_DATA_TYPE, URL_TYPE } from 'enum/enum';
import UseApiService from './apiService';
import { GroupData } from 'interface/interface';

export const createGroup = (groupName: string) => {
  const body = {
    url: API_END_POINTS.GROUPS,
    data: { name: groupName },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };

  return UseApiService().post(body);
};

export const getGroups = () => {
  const body = {
    url: API_END_POINTS.GROUPS,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};

export const getGroupProducts = (id: string, requestUrl = '', type = URL_TYPE.NEXT) => {
  let body: any = {
    url: `${API_END_POINTS.GROUPS}/${id}${API_END_POINTS.PRODUCTS}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  if (requestUrl) {
    body = { ...body, data: { [type]: requestUrl } };
  }
  return UseApiService().get(body);
};

export const deleteGroup = (id: string) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${id}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().delete(body);
};

export const modifyGroup = (group: GroupData) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${group?.id}`,
    data: {
      name: group?.name,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().patch(body);
};

export const removeProductsFromGroup = (id: string, products: string[]) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${id}${API_END_POINTS.PRODUCTS}`,
    data: { products },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().delete(body);
};

export const addProductsToGroup = (id: string, products: string[], overrideSizeMismatch: boolean) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${id}${API_END_POINTS.PRODUCTS}`,
    data: { products, overrideSizeMismatch },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};

export const fetchGroupSizes = (id: string) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${id}${API_END_POINTS.SIZES}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};

export const groupOnboard = (id: string, onboarded: boolean) => {
  const body = {
    url: `${API_END_POINTS.GROUPS}/${id}${API_END_POINTS.ONBOARD}`,
    data: {
      onboarded,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().patch(body);
};
