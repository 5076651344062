import React from 'react';
import styles from './PageTitle.module.scss';
// import home from 'assets/images/home-breadcrum.svg';
// import { Link } from 'react-router-dom';
// import { ROUTES } from 'constants/constants';
// import upload from 'assets/images/upload.svg';
// import plus from 'assets/images/plus-circle.svg';

const PageTitle = ({ name }: { name: string }) => {
  return (
    <div className={`${styles.header}`}>
      {/* <ul>
        <li>
          <img src={home} alt="home" />
        </li>
        <li>Measurements</li>
        <li>
          <Link to={ROUTES.HOME}>Sources</Link>
        </li>
      </ul> */}
      <div className={`${styles.title} `}>
        <h1>Welcome back, {name}</h1>
        <p>Measure your ROI and track engagement.</p>
        {/* <div className={` flex ${styles.button}`}>
          <button className="button outline">Import</button>
          <button className="button secondary">Share</button>
        </div> */}
      </div>
    </div>
  );
};

export default PageTitle;
