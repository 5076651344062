import Card from 'component/card/Card';
import TableCompoWithoutCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithoutCheckBox';
import { groupHeadCell } from 'component/table/tableCompoWithCheckBox/tableData';
import styles from './CustomGroup.module.scss';
import React from 'react';
import Pagination from 'component/pagination/Pagination';
import noScanImg from '../../assets/images/bubble-no-scan.svg';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
interface CustomGroupProps {
  handleSelectedName: (a: Record<string, any>) => void;
  customFitData: Record<string, any>;
  pageSize: number;
  pageNo: number;
  setPageNo: (a: number) => void;
}

const CustomGroup = ({ handleSelectedName, customFitData, pageSize, pageNo, setPageNo }: CustomGroupProps) => {
  return (
    <div className={`dflex ${styles.customGroup}`}>
      <div className={` ${styles.customGroup__card}`}>
        <Card title="Customers measured" value={customFitData?.scans?.length ?? 0} />
      </div>
      <div className={` ${styles.customGroup__card}`}>
        <Card title="Time saved" value={`${(customFitData?.scans?.length ?? 0) * 30} mins`} />
      </div>
      <div className={`dflex table-group ${customFitData?.scans?.length > 0 ? `` : styles.noShadow} ${styles.customGroup__table}`}>
        {customFitData?.scans?.length > 0 ? (
          <>
            <TableCompoWithoutCheckBox reviewRows={customFitData?.scans ?? []} handleSelectedName={handleSelectedName} customGroup={true} headCells={groupHeadCell} customName />
            <Pagination secondary={false} setPageNo={setPageNo} pageNo={pageNo} pageSize={pageSize} data={customFitData?.scans ?? []} />
          </>
        ) : (
          <div className="custom-group-noData">
            <NoDataPlaceHolder imgUrl={noScanImg} title="No Scans Found" message="Share the link to gather data" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomGroup;
