import React, { ChangeEvent } from 'react';
import style from './Textarea.module.scss';
export interface TextAreaProps {
  label?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
}

const TextArea = ({ label, value, onChange, placeholder = '', name = '', autoFocus = false }: TextAreaProps) => {
  return (
    <div className={style.textBox}>
      {label && <label>{label}</label>}
      <textarea autoFocus={autoFocus} rows={1} cols={1} value={value} name={name} placeholder={placeholder} onChange={onChange}></textarea>
    </div>
  );
};
export default TextArea;
