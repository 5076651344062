import React from 'react';
import { ContextWrapper } from 'context/ContextWrapper';
import { Outlet } from 'react-router-dom';
import 'assets/globals.scss';

function App() {
  return (
    <div className="App">
      <ContextWrapper>
        <Outlet />
      </ContextWrapper>
    </div>
  );
}

export default App;
