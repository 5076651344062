import { DATE_PICKER_VALUE } from 'enum/enum';
import { ApexChartProps } from 'interface/interface';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { getXAxisPoints } from 'utils/utils';

const ApexChart = ({ data = [], datePickerValue, showYaxis = true, height = 350 }: ApexChartProps) => {
  // eslint-disable-next-line prefer-const
  let [xaxisPointsObject, maxi] = getXAxisPoints(data);

  let labelsCount = 7;
  switch (datePickerValue) {
    case DATE_PICKER_VALUE.THIRTY_DAYS: {
      labelsCount = 30;
      break;
    }
    case DATE_PICKER_VALUE.FOURTEEN_DAYS: {
      labelsCount = 14;
      break;
    }
    case DATE_PICKER_VALUE.SEVEN_DAYS: {
      labelsCount = 7;
      break;
    }
  }
  const labels = [];
  for (let i = 1; i <= labelsCount; i++) {
    const today = new Date();
    const date = new Date(today.setDate(today.getDate() - i)).toISOString().split('T')[0];
    labels.push(date);
  }

  const series = [
    {
      name: 'users',
      data: labels.map((key) => ({ x: key, y: xaxisPointsObject[key] ?? 0 })),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 1.5,
    },
    colors: ['#5932F3'],
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      show: showYaxis,
    },
    xaxis: {
      type: 'datetime',
      categories: labels,
      tickAmount: labelsCount,
      labels: {
        showDuplicates: false,
        format: 'dd MMM',
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      tickAmount: maxi + 1,
      max: maxi + 1,
      labels: {
        show: showYaxis,
      },
    },
  } as any;

  return (
    <div>
      <ReactApexChart options={options} series={series} type="line" height={height} />
    </div>
  );
};

export default ApexChart;
