import AnkleGirthMale from '../../../assets/modal-images/Ankle-Girth-M.jpg';
import ArmLengthMale from '../../../assets/modal-images/Arm-Length-M.jpg';
import BackWidthMale from '../../../assets/modal-images/Back-Width-M.jpg';
import BustGirthMale from '../../../assets/modal-images/Bust-Girth-M.jpg';
import CalfGirthMale from '../../../assets/modal-images/Calf-Girth-M.jpg';
import ElbowGirthMale from '../../../assets/modal-images/Elbow-Girth-M.jpg';
import ForeArmGirthMale from '../../../assets/modal-images/Forearm-Girth-M.jpg';
import HeadGirth from '../../../assets/modal-images/Head-Hirth.jpg';
import HipGirthMale from '../../../assets/modal-images/Hip-Girth-M.jpg';
import HighHipGirthMale from '../../../assets/modal-images/High-Hip-Girth-M.jpg';
import InseamToFloorMale from '../../../assets/modal-images/Inseam to Floor-M.jpg';
import InseamToAnkleMale from '../../../assets/modal-images/Inseam-to-Ankle-M.jpg';
import KneeGirthMale from '../../../assets/modal-images/knee-girth-M.jpg';
import NapeToWaistMale from '../../../assets/modal-images/Nape-to-Waist-M.jpg';
// import NeckGirthMale from '../../../assets/modal-images/Neck-Girth-M.jpg';
import NeckBaseGirthMale from '../../../assets/modal-images/Neck-Base-Girth-M.jpg';
import ShoulderWidthMale from '../../../assets/modal-images/Shoulder-Width-M.jpg';
import ThighGirthMale from '../../../assets/modal-images/Thigh-Girth-M.jpg';
import TorsoLengthMale from '../../../assets/modal-images/Torso-Length-M.jpg';
import TotalRiseMale from '../../../assets/modal-images/Total-Rise-M.jpg';
import UnderBustGirthMale from '../../../assets/modal-images/Underbust-Girth-M.jpg';
import UpperArmCircumMale from '../../../assets/modal-images/Upper-Arm-Circumference-M.jpg';
import WaistGirthMale from '../../../assets/modal-images/Waist-Girth-M.jpg';
import WristGirthMale from '../../../assets/modal-images/Wrist-Girth-M.jpg';
import AnkleGirthFemale from '../../../assets/modal-images/Ankle-Girth-F.jpg';
import ArmLengthFemale from '../../../assets/modal-images/Arm-Length-F.jpg';
import BackWidthFemale from '../../../assets/modal-images/Back-Width-F.jpg';
import BustGirthFemale from '../../../assets/modal-images/Bust-Girth-F.jpg';
import CalfGirthFemale from '../../../assets/modal-images/Calf-Girth-F.jpg';
import ElbowGirthFemale from '../../../assets/modal-images/Elbow-Girth-F.jpg';
import ForeArmGirthFemale from '../../../assets/modal-images/Forearm-Girth-F.jpg';
import HipGirthFemale from '../../../assets/modal-images/Hip-Girth-F.jpg';
import HighHipGirthFemale from '../../../assets/modal-images/High-Hip-Girth-F.jpg';
import InseamToFloorFemale from '../../../assets/modal-images/Inseam to Floor-F.jpg';
import InseamToAnkleFemale from '../../../assets/modal-images/Inseam-to-Ankle-F.jpg';
import KneeGirthFemale from '../../../assets/modal-images/knee-girth-F.jpg';
import NapeToWaistFemale from '../../../assets/modal-images/Nape-to-Waist-F.jpg';
import NeckGirthFemale from '../../../assets/modal-images/Neck-Girth-F.jpg';
import NeckBaseGirthFemale from '../../../assets/modal-images/Neck-Base-Girth-F.jpg';
import ShoulderWidthFemale from '../../../assets/modal-images/Shoulder-Width-F.jpg';
import ThighGirthFemale from '../../../assets/modal-images/Thigh-Girth-F.jpg';
import TorsoLengthFemale from '../../../assets/modal-images/Torso-Length-F.jpg';
import TotalRiseFemale from '../../../assets/modal-images/Total-Rise-F.jpg';
import UnderBustGirthFemale from '../../../assets/modal-images/Underbust-Girth-F.jpg';
import UpperArmCircumFemale from '../../../assets/modal-images/Upper-Arm-Circumference-F.jpg';
import WaistGirthFemale from '../../../assets/modal-images/Waist-Girth-F.jpg';
import WristGirthFemale from '../../../assets/modal-images/Wrist-Girth-F.jpg';
import AcrossShoulderFemale from '../../../assets/modal-images/Across-Shoulder-F.jpg';
import AcrossShoulderMale from '../../../assets/modal-images/Across-Shoulder-M.jpg';

export const reviewHeadCells = [
  {
    id: '0001',
    label: 'Measurement',
  },
  {
    id: '0002',
    label: 'Value',
  },
];

export const reviewRows1 = [
  {
    id: 1,
    measurement: 'Neck',
    // Url: 'Neck',
    value: 40,
  },
  {
    id: 2,
    measurement: 'Shoulders',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 3,
    measurement: 'Waist',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 4,
    measurement: 'High Waist',
    // Url: 'Neck',
    value: 75,
  },
  {
    id: 5,
    measurement: 'Upper Bust',
    // Url: 'Neck',
    value: 97,
  },
  {
    id: 6,
    measurement: 'Bust',
    // Url: 'Neck',
    value: 103,
  },
  {
    id: 7,
    measurement: 'Lower Bust',
    // Url: 'Neck',
    value: 101,
  },
];
export const reviewRows2 = [
  {
    id: 1,
    measurement: 'Neck',
    // Url: 'Neck',
    value: 40,
  },
  {
    id: 2,
    measurement: 'Shoulders',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 3,
    measurement: 'Waist',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 4,
    measurement: 'High Waist',
    // Url: 'Neck',
    value: 75,
  },
  {
    id: 5,
    measurement: 'Upper Bust',
    // Url: 'Neck',
    value: 97,
  },
  {
    id: 6,
    measurement: 'Bust',
    // Url: 'Neck',
    value: 103,
  },
  {
    id: 7,
    measurement: 'Lower Bust',
    // Url: 'Neck',
    value: 101,
  },
];

export const groupHeadCell = [
  {
    label: 'Name',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d790334',
  },
  {
    label: 'Email',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
  },
  {
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    label: 'Gender',
    center: true,
  },
  {
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e13',
    label: 'Measurement Taken',
    center: true,
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b005',
    label: 'Last Measured',
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b555',
    label: 'Notes',
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b55599',
    label: '',
  },
];

export const customGroupRows = [
  {
    id: 1,
    email: 'andrew@7till8.com',
    gender: 'Male',
    orderLinked: 'Mens Surf Fullsuit',
    measured: '22 Jan 2022',
    notes: 'Prefers a fit which...',
    name: 'Andrew Park',
  },
  {
    id: 2,
    email: 'neal@getswan.co',
    gender: 'Male',
    orderLinked: 'Mens Front Zip Jacket',
    measured: '20 Jan 2022',
    notes: 'wants extra room',
    name: 'Neal Mc Namara',
  },

  {
    id: 3,
    email: 'eoin@getswan.co',
    gender: 'Male',
    orderLinked: '',
    measured: '24 Jan 2022',
    notes: '',
    name: 'Eoin Cambay',
  },
  {
    id: 4,
    email: 'customer@gmail.com',
    gender: 'Male',
    orderLinked: 'Surf Fullsuit',
    measured: '26 Jan 2022',
    notes: '',
    name: 'Customer',
  },
  {
    id: 5,
    email: 'customer@gmail.com',
    gender: 'Female',
    orderLinked: 'Womens Short Jane',
    measured: '18 Jan 2022',
    notes: 'Love her Hooded...',
    name: 'Customer',
    female: true,
  },
  {
    id: 6,
    email: 'customer@gmail.com',
    gender: 'Male',
    orderLinked: 'Shipped',
    measured: '28 Jan 2022',
    notes: '',
    name: 'Customer',
    shipped: true,
  },
];

export const calibrationHeadCell = [
  // {
  //   id: 'b9e1c9a5-5fda-420c-871f-3a839cc33f133',
  //   label: 'Customer',
  // },
  {
    label: 'Email',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
  },
  {
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    label: 'Gender',
  },

  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b005',
    label: 'Scanned on',
  },
  {
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e13',
    label: 'Upload measurements',
    center: true,
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b555',
    label: '',
  },
];

export const calibrationRows = [
  {
    id: 1,
    email: 'andrew@7till8.com',
    gender: 'Male',
    scannedOn: '22 Jan 2022',
    measurements: 'Upload',
    name: 'Andrew Park',
  },
  {
    id: 2,
    email: 'neal@getswan.co',
    gender: 'Male',
    scannedOn: '20 Jan 2022',
    measurements: 'Upload',
    name: 'Neal Mc Namara',
  },

  {
    id: 3,
    email: 'eoin@getswan.co',
    gender: 'Male',
    scannedOn: '24 Jan 2022',
    measurements: 'Upload',
    name: 'Eoin Cambay',
  },
  {
    id: 4,
    email: 'customer@gmail.com',
    gender: 'Male',
    scannedOn: '26 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
  },
  {
    id: 5,
    email: 'customer@gmail.com',
    gender: 'Female',
    scannedOn: '18 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
    female: true,
  },
  {
    id: 6,
    email: 'customer@gmail.com',
    gender: 'Male',
    scannedOn: '28 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
  },
];

export const ModalTableData: any = {
  male: {
    ankle: {
      title: 'Ankle Girth',
      path: AnkleGirthMale,
      desc: 'Minimum girth of the lower leg, horizontally just above the ankle.',
    },
    armLength: {
      title: 'Arm Length',
      path: ArmLengthMale,
      desc: 'From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.',
    },
    armLengthStraight: {
      title: 'Arm Length Straight',
      path: ArmLengthMale,
      desc: 'From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.',
    },
    bicep: {
      title: 'Bicep', //Image not available
      path: UpperArmCircumMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    calf: {
      title: 'Calf Girth',
      path: CalfGirthMale,
      desc: 'Maximum girth of the calf measured horizontally at the fullest part of the calf with legs slightly apart.',
    },
    bust: {
      title: 'Bust Girth',
      path: BustGirthMale,
      desc: 'Maximally horizontal circumference measured under the armpits and at the level of the maximum projection of the bust with regular breathing.',
    },
    forearm: {
      title: 'Forearm Girth',
      path: ForeArmGirthMale,
      desc: 'Girth of the forearm in the middle between elbow and wrist, orthogonal to the axis of the forearm.',
    },
    head: {
      title: 'Head Girth',
      path: HeadGirth,
      desc: 'Full head circumference measurement over the eyebrow bridge.',
    },
    hip: {
      title: 'Hip Girth',
      path: HipGirthMale,
      desc: 'Maximum horizontal circumference of the trunk measured at hip height at the fullest part of the buttocks.',
    },
    inseamToAnkle: {
      title: 'Inseam To Ankle',
      path: InseamToAnkleMale,
      desc: 'Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.',
    },
    inseam: {
      title: 'Inseam',
      path: InseamToAnkleMale,
      desc: 'Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs',
    },
    knee: {
      title: 'Knee Girth',
      path: KneeGirthMale,
      desc: 'Circumference of the knee.',
    },
    neckCirc: {
      title: 'Neck Girth',
      path: NeckBaseGirthMale,
      desc: 'Circumference of the neck, measured close to the prominent larynx cartilage (higher than neck base) and the 7th cervical vertebra.',
    },
    shoulder: {
      title: 'Shoulder Width',
      path: ShoulderWidthMale,
      desc: 'From the 7th Cervical Vertebra to the shoulder bone.',
    },
    thigh: {
      title: 'Thigh Girth',
      path: ThighGirthMale,
      desc: 'Horizontal girth at the highest thigh position.',
    },
    torso: {
      title: 'Torso Length',
      path: TorsoLengthMale,
      desc: 'From the 7th Cervical Vertebra following the centre back contour to the crotch.',
    },
    underBust: {
      title: 'UnderBust Girth',
      path: UnderBustGirthMale,
      desc: 'Circumference of the chest under the bust.',
    },
    wrist: {
      title: 'Wrist Girth',
      path: WristGirthMale,
      desc: 'Girth of the wrist measured over the wrist bones.',
    },
    back: {
      title: 'Back Width',
      path: BackWidthMale,
      desc: 'Taken horizontally, in the back, the distance between armpits, at a vertical level between the armpits and the collar bone.',
    },
    elbow: {
      title: 'Elbow Girth',
      path: ElbowGirthMale,
      desc: 'Circumference of the arm at the elbow with the arms hanging down and slightly tilted elbows.',
    },
    highHip: {
      title: 'High-Hip Girth',
      path: HighHipGirthMale,
      desc: 'Horizontal circumference of the trunk, measured midway between waist level and hip level.',
    },
    inseamToFloor: {
      title: 'Inseam To Floor',
      path: InseamToFloorMale,
      desc: 'Distance between the crotch level and the ground, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.',
    },
    napeToWaist: {
      title: 'Nape To Waist',
      path: NapeToWaistMale,
      desc: 'Vertical distance from the 7th cervical vertebra to the waist, measured following the contour of the spinal column to the waist level.',
    },
    neckBase: {
      title: 'Neck-Base Girth',
      path: NeckBaseGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    totalRise: {
      title: 'Total-Rise',
      path: TotalRiseMale,
      desc: 'Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.',
    },
    upperArm: {
      title: 'Upper-Arm Circumference',
      path: UpperArmCircumMale,
      desc: 'Circumference of bicep right below the deltoid.',
    },
    acrossBackShoulder: {
      title: 'Across Back Shoulder',
      path: AcrossShoulderMale,
      desc: 'Taken horizontally, in the back, the distance between armpits, at a vertical level between the armpits and the collar bone.',
    },
    waist: {
      title: 'Waist Girth',
      path: WaistGirthMale,
      desc: 'Horizontal circumference at natural waistline between the highest part of the hip bones and the lower ribs',
    },
    seat: {
      title: 'Seat',
      path: TotalRiseMale,
      desc: 'Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.',
    },
  },
  female: {
    ankle: {
      title: 'Ankle Girth',
      path: AnkleGirthFemale,
      desc: 'Minimum girth of the lower leg, horizontally just above the ankle.',
    },
    acrossBackShoulder: {
      title: 'Across Back Shoulder',
      path: AcrossShoulderFemale,
      desc: 'Taken horizontally, in the back, the distance between armpits, at a vertical level between the armpits and the collar bone.',
    },
    armLength: {
      title: 'Arm Length',
      path: ArmLengthFemale,
      desc: 'From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.',
    },
    armLengthStraight: {
      title: 'Arm Length Straight',
      path: ArmLengthFemale,
      desc: 'From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.',
    },
    bicep: {
      title: 'Bicep', //Image not available
      path: UpperArmCircumFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    calf: {
      title: 'Calf Girth',
      path: CalfGirthFemale,
      desc: 'Maximum girth of the calf measured horizontally at the fullest part of the calf with legs slightly apart.',
    },
    bust: {
      title: 'Bust Girth',
      path: BustGirthFemale,
      desc: 'Maximally horizontal circumference measured under the armpits and at the level of the maximum projection of the bust with regular breathing.',
    },
    seat: {
      title: 'Seat',
      path: TotalRiseFemale,
      desc: 'Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.',
    },
    forearm: {
      title: 'Forearm Girth',
      path: ForeArmGirthFemale,
      desc: 'Girth of the forearm in the middle between elbow and wrist, orthogonal to the axis of the forearm.',
    },
    head: {
      title: 'Head Girth',
      path: HeadGirth,
      desc: 'Full head circumference measurement over the eyebrow bridge.',
    },
    hip: {
      title: 'Hip Girth',
      path: HipGirthFemale,
      desc: 'Maximum horizontal circumference of the trunk measured at hip height at the fullest part of the buttocks.',
    },
    inseamToAnkle: {
      title: 'Inseam To Ankle',
      path: InseamToAnkleFemale,
      desc: 'Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.',
    },
    inseam: {
      title: 'Inseam',
      path: InseamToAnkleFemale,
      desc: 'Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.',
    },
    knee: {
      title: 'Knee Girth',
      path: KneeGirthFemale,
      desc: 'Circumference of the knee',
    },
    neckCirc: {
      title: 'Neck Girth',
      path: NeckGirthFemale,
      desc: 'Circumference of the neck, measured close to the prominent larynx cartilage (higher than neck base) and the 7th cervical vertebra.',
    },
    shoulder: {
      title: 'Shoulder Width',
      path: ShoulderWidthFemale,
      desc: 'From the 7th Cervical Vertebra to the shoulder bone.',
    },
    thigh: {
      title: 'Thigh Girth',
      path: ThighGirthFemale,
      desc: 'Horizontal girth at the highest thigh position.',
    },
    torso: {
      title: 'Torso Length',
      path: TorsoLengthFemale,
      desc: 'From the 7th Cervical Vertebra following the centre back contour to the crotch.',
    },
    underBust: {
      title: 'UnderBust Girth',
      path: UnderBustGirthFemale,
      desc: 'Circumference of the chest under the bust.',
    },
    wrist: {
      title: 'Wrist Girth',
      path: WristGirthFemale,
      desc: 'Girth of the wrist measured over the wrist bones.',
    },
    back: {
      title: 'Back Width',
      path: BackWidthFemale,
      desc: 'Taken horizontally, in the back, the distance between armpits, at a vertical level between the armpits and the collar bone.',
    },
    elbow: {
      title: 'Elbow Girth',
      path: ElbowGirthFemale,
      desc: 'Circumference of the arm at the elbow with the arms hanging down and slightly tilted elbows.',
    },
    highHip: {
      title: 'High-Hip Girth',
      path: HighHipGirthFemale,
      desc: 'Horizontal circumference of the trunk, measured midway between waist level and hip level.',
    },
    inseamToFloor: {
      title: 'Inseam To Floor',
      path: InseamToFloorFemale,
      desc: 'Distance between the crotch level and the ground, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.',
    },
    napeToWaist: {
      title: 'Nape To Waist',
      path: NapeToWaistFemale,
      desc: 'Vertical distance from the 7th cervical vertebra to the waist, measured following the contour of the spinal column to the waist level.',
    },
    neckBase: {
      title: 'Neck-Base Girth',
      path: NeckBaseGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    totalRise: {
      title: 'Total-Rise',
      path: TotalRiseFemale,
      desc: 'Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.',
    },
    upperArm: {
      title: 'Upper-Arm Circumference',
      path: UpperArmCircumFemale,
      desc: 'Circumference of bicep right below the deltoid.',
    },
    waist: {
      title: 'Waist Girth',
      path: WaistGirthFemale,
      desc: 'Horizontal circumference at natural waistline between the highest part of the hip bones and the lower ribs.',
    },
  },
};
