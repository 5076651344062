import React from 'react';
import style from './GroupList.module.scss';
import GroupCard from 'component/groupCard/GroupCard';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import { GroupListProps } from 'interface/interface';

const GroupList = ({ groups, handleTabsChange, handleShowModal, buttonDisabled }: GroupListProps) => {
  return (
    <div className={style.groupBlock}>
      <div className={`${style.groupBlock__wrap} dflex`}>
        {groups?.length > 0 ? (
          groups?.map((item, index) => (
            <div className={`${style.groupBlock__item} dflex`} key={item?.id + index}>
              <GroupCard group={item} handleTabsChange={handleTabsChange} handleShowModal={handleShowModal} buttonDisabled={buttonDisabled} />
            </div>
          ))
        ) : (
          <div className="dflex alignCenter justifyCenter w-full">
            <NoDataPlaceHolder message="No Groups found in this list" />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupList;
