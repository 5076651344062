import ResourcesCard from 'component/resourcesCard/ResourcesCard';
import styles from './Resources.module.scss';
import React from 'react';
import Pagination from 'component/pagination/Pagination';
import { ResourcesData } from 'constants/constants';

const Resources = () => {
  return (
    <div className={` ${styles.resources}`}>
      <div className={` ${styles.resources__header}`}>
        <p>
          <span>View all</span>
        </p>
      </div>
      <div className={` dflex ${styles.resources__cards}`}>
        {ResourcesData.map((el) => (
          <div className={` ${styles.card}`} key={el.id}>
            <ResourcesCard resource={el} />
          </div>
        ))}
      </div>
      <Pagination secondary={true} />
    </div>
  );
};

export default Resources;
