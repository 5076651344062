import React, { useContext } from 'react';
import styles from './CustomNameDetails.module.scss';
// import AU from 'assets/images/AU.svg';
import arrow from 'assets/images/arrow-up-right-blue.svg';
import sheet from 'assets/images/google-sheets.png';
import mail from 'assets/images/mail.svg';
import CustomButton from 'component/CustomButton';
import { capitalizeFirstLetter, dateFormat } from 'utils/utils';
import { AuthContext } from 'context/AuthContext';
import { AuthContextInterface } from 'interface/interface';

interface CustomNameDetailLeftProps {
  selectedValue: Record<string, any>;
  handleExportScanData: () => Promise<void>;
  buttonDisabled: boolean;
}

const CustomNameDetailLeft = ({ selectedValue, handleExportScanData, buttonDisabled }: CustomNameDetailLeftProps) => {
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;
  return (
    <div className={` ${styles.detailLeft}`}>
      <div className={` ${styles.stickyWrapper}`}>
        <div className={` ${styles.boxBorder}`}>
          <div className={` ${styles.boxBorder__header}`}>
            <h2>Profile</h2>
          </div>
          <div className={` ${styles.list}`}>
            <h4>Customer</h4>
            <p className="flex">{selectedValue?.profile?.userName || '-'}</p>
          </div>
          {/* <div className={` ${styles.list}`}> */}
          {/* <h4>Location</h4> */}
          {/* <p className="flex"> */}
          {/* <img src={AU} alt="header" /> Melbourne, Australia */}
          {/* {selectedValue?.profile?.location || '-'} */}
          {/* </p> */}
          {/* </div> */}
          <div className={` ${styles.list}`}>
            <h4>Gender</h4>
            <p>{capitalizeFirstLetter(selectedValue?.profile?.gender)}</p>
          </div>
          <div className={` ${styles.list}`}>
            <h4>Last Scanned on</h4>
            <p>{dateFormat(selectedValue?.profile?.scannedAt)}</p>
          </div>
          <div className={` ${styles.list}`}>
            <h4>Email</h4>
            <p>
              <a className="flex" href={`mailto:${selectedValue?.profile?.userEmail}`}>
                {selectedValue?.profile?.userEmail || '-'} <img src={arrow} alt="icon" />
              </a>
            </p>
          </div>
        </div>
        <div className={` ${styles.boxBorder}`}>
          <div className={` ${styles.boxBorder__header}`}>
            <h2>Export</h2>
          </div>
          <div className={`flex alignStart justifySpaceBetween ${styles.excelBox}`}>
            <div className={`flex alignStart ${styles.excelBoxLeft} `}>
              <figure>
                <img src={sheet} alt="icon" />
              </figure>
              <figcaption>
                <h5>Google Sheets</h5>
                <p>Standard template</p>
                <p>
                  <a href="mailto:brand@brand.com">
                    <img src={mail} alt="main" />
                    {userDetails?.email ?? ''}
                  </a>
                </p>
              </figcaption>
            </div>
            <div className={` ${styles.buttonBottom} `}>
              <CustomButton buttonText="Export" className={'outline button gray'} handleFunc={handleExportScanData} disabled={buttonDisabled} loading={buttonDisabled} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNameDetailLeft;
