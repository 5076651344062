import CustomButton from 'component/CustomButton';
import React, { useContext } from 'react';
import styles from './AuthAndLoadProducts.module.scss';
import { AuthAndLoadProductsProps, AuthContextInterface } from 'interface/interface';
import { SHOW_CASE } from 'enum/enum';
import { AuthContext } from 'context/AuthContext';

const AuthAndLoadProducts = ({ showCase, handleAuthenticate, loading, fetchProducts }: AuthAndLoadProductsProps) => {
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;

  return (
    <div className={`${styles.loadWrapMain}`}>
      <div className={`flex alignCenter justifySpaceBetween ${styles.loadWrap}`}>
        {showCase === SHOW_CASE.LOAD_PRODUCT ? (
          <>
            <div className={` ${styles.loadWrap__info}`}>
              <h2> Load all your products from {userDetails?.store_url}</h2>
              <p>1 min max </p>
            </div>
            <CustomButton
              buttonText="Load"
              className={'button'}
              handleFunc={() => {
                if (fetchProducts) {
                  fetchProducts();
                }
              }}
              loading={loading}
              disabled={loading}
            />
          </>
        ) : (
          <>
            <div className={` ${styles.loadWrap__info}`}>
              <h2> Authenticate Shopify Store</h2>
              <p>Please link your store</p>
            </div>
            <CustomButton buttonText="Link" className={'button'} handleFunc={handleAuthenticate} loading={loading} disabled={loading} />
          </>
        )}
      </div>
    </div>
  );
};

export default AuthAndLoadProducts;
