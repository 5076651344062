/* eslint-disable max-lines */
import CircularLoader from 'component/CircularLoader';
import { AuthContext } from 'context/AuthContext';
import { DATE_PICKER_VALUE, FETCH_LIST_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import React, { useContext, useEffect, useState } from 'react';
import { fetchList, getProductsSupported } from 'services/listService';
import styles from './List.module.scss';
import { ListChildNew } from './ListChildNew';
import PageTitle from 'component/pageTitle/PageTitle';
import { FULFILLED, REACT_APP_POSTHOG_LIVE_PROJECT_ID } from 'constants/constants';
import { removeUnwantedEmails } from 'utils/utils';

const List = () => {
  const [datePickerValue, setDatePickerValue] = useState<DATE_PICKER_VALUE>(DATE_PICKER_VALUE.SEVEN_DAYS);
  const [scanUsersList, setScanUsersList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noOfSizingSuggestions, setNoOfSizingSuggestions] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<any>({});

  const { userDetails } = useContext(AuthContext) as AuthContextInterface;

  const fetchAllList = async () => {
    setLoading(true);
    try {
      const fetchPromisesCombined = [
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after: datePickerValue, event: userDetails?.store_url }),
        getProductsSupported(),
        fetchList({
          projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '',
          after: datePickerValue,
          event: FETCH_LIST_TYPE.SIZING_SUGGESTIONS,
          extraParams: `"store_url":"${userDetails?.store_url}"`,
        }),
      ];

      let userListLive: any[] = [],
        sizingSuggestionLive = 0,
        swanPSLive: any[] = [];
      const resolvedResults = await Promise.allSettled(fetchPromisesCombined);

      resolvedResults?.forEach((result, index) => {
        if (result?.status === FULFILLED) {
          const data: any = result?.value;
          switch (index) {
            case 0: {
              userListLive = data;
              break;
            }
            case 1: {
              swanPSLive = data?.data;
              break;
            }
            default: {
              sizingSuggestionLive = data?.length;
            }
          }
        }
      });

      setScanUsersList(removeUnwantedEmails(userListLive));
      setNoOfSizingSuggestions(sizingSuggestionLive);
      setProductDetails(swanPSLive);
    } catch (err) {
      console.log('error=', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (datePickerValue !== DATE_PICKER_VALUE.CUSTOM) {
      fetchAllList();
    }
  }, [datePickerValue]);

  return (
    <div className={`${styles.listMain}`}>
      {loading && <CircularLoader />}
      {/* {!loading && (
        <CustomDialogModal
          open={open}
          handleShowModal={handleShowModal}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          fetchAllList={fetchAllList}
        />
      )} */}
      {!loading && (
        <>
          <PageTitle name={userDetails?.name} />
          <div className={`${styles.main}`}>
            <div className={`${styles.dateSelect}`}>
              <ul>
                {/* <li>
                    <span>12 Months</span>
                </li> */}

                <li className={datePickerValue === DATE_PICKER_VALUE.SEVEN_DAYS ? styles.active : ''} onClick={() => setDatePickerValue(DATE_PICKER_VALUE.SEVEN_DAYS)}>
                  <span>7 days</span>
                </li>
                <li className={datePickerValue === DATE_PICKER_VALUE.THIRTY_DAYS ? styles.active : ''} onClick={() => setDatePickerValue(DATE_PICKER_VALUE.THIRTY_DAYS)}>
                  <span>30 days</span>
                </li>
                {/* <li>
                    <span>24 hours</span>
                </li> */}
              </ul>
            </div>

            <ListChildNew
              usersList={scanUsersList}
              datePickerValue={datePickerValue}
              noOfSizingSuggestions={noOfSizingSuggestions}
              // swanPoweredSalesData={swanPoweredSalesData}
              productDetails={productDetails}
            />
          </div>

          {/* <div className={`${styles.datePicker}`}>
            <DateFilter
              setDatePickerValue={setDatePickerValue}
              datePickerValue={datePickerValue}
              handleShowModal={handleShowModal}
              startDate={getDate(startDate)}
              endDate={getDate(endDate)}
            />
          </div> */}
        </>
      )}
    </div>
  );
};

export default List;
