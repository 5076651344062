import CircularLoader from 'component/CircularLoader';
import { ROUTES } from 'constants/constants';
import { AuthContext } from 'context/AuthContext';
import { AuthContextInterface } from 'interface/interface';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const LoggedOutWrapper = ({ children }: { children: React.ReactNode }) => {
  const { auth, loading } = useContext(AuthContext) as AuthContextInterface;
  if (loading) {
    return <CircularLoader />;
  } else if (!auth) {
    return <>{children}</>;
  } else {
    return <Navigate to={ROUTES.HOME} replace />;
  }
};

export default LoggedOutWrapper;
