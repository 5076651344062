import AnalyticsCard from 'component/analyticsCard/AnalyticsCard';
import { Metrics } from 'enum/enum';
import React from 'react';
import { kpiCalculation } from 'utils/utils';
import styles from './AnalyticGlobalCards.module.scss';
import { AnalyticCardsProps } from 'interface/interface';

const AnalyticGlobalCards = ({ scanStartedData, datePickerValue, confirmationRate }: AnalyticCardsProps) => {
  return (
    <div className={`${styles.cardList} dflex`}>
      <div className={`${styles.card}`}>
        <AnalyticsCard
          title={Metrics.EMAIL_VERIFICATION_RATE}
          value={kpiCalculation(confirmationRate?.length, scanStartedData?.length, true)}
          data={confirmationRate}
          datePickerValue={datePickerValue}
          showGraph
        />
      </div>
    </div>
  );
};

export default AnalyticGlobalCards;
