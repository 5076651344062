import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import edit from 'assets/images/edit.svg';
import CustomButton from 'component/CustomButton';
import { AlterType, TechPackChildren } from 'enum/enum';
import { RowsData, TechPackData } from 'interface/interface';
import React, { useMemo } from 'react';
import { getCalculation } from 'utils/utils';
interface TableWithoutCheckBoxProps {
  techPackRes: TechPackData;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, parent: TechPackChildren) => void;
  handleDeleteRow: (rowName: string, id: string) => void;
  handleAllowEditColumn: (id: string, type: AlterType, save?: boolean) => void;
  rows: RowsData[];
  techPackButtonDisabled?: boolean;
}

const TechPackTable = ({ handleOnChange, techPackRes, handleDeleteRow, handleAllowEditColumn, rows }: TableWithoutCheckBoxProps) => {
  const EaseData = useMemo(() => Object.entries(techPackRes?.ease), [techPackRes]);
  const SizesData = useMemo(() => Object.entries(techPackRes?.sizes), [techPackRes]);

  return (
    <>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Measurement</TableCell>
              {/* <TableCell>Fit model</TableCell>
              <TableCell>Critical for fit</TableCell> */}
              {SizesData?.map(([key, value]: [any, any]) => (
                <TableCell key={value?.id}>{key}</TableCell>
              ))}
              {EaseData?.length > 0 ? EaseData?.map(([key, value]: [any, any]) => <TableCell key={value?.id}>Ease({key})</TableCell>) : <TableCell>Ease(-)</TableCell>}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: RowsData) => (
              <TableRow key={row?.id}>
                <TableCell>{row?.name}</TableCell>
                {/* <TableCell>-</TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <div className="flex alignCenter justifyCenter">
                    <Checkbox color="primary" />
                  </div>
                </TableCell> */}
                {SizesData?.map(([key, value]: [any, any], index: number) => (
                  <TableCell key={value?.id} onDoubleClick={() => handleAllowEditColumn(row?.id, AlterType.ONLY_EDIT)}>
                    <input
                      type="number"
                      name={row?.name}
                      id={key}
                      readOnly={row?.notEdit}
                      value={value?.[row?.name]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, TechPackChildren.SIZES)}
                    />
                    {index > 0 && <p key={value?.id}>{getCalculation(SizesData, index, row?.name) ?? 0}</p>}
                  </TableCell>
                ))}
                {EaseData?.map(([key, value]: [any, any]) => (
                  <TableCell key={value?.id} onDoubleClick={() => handleAllowEditColumn(row?.id, AlterType.ONLY_EDIT)}>
                    <input
                      type="number"
                      name={row?.name}
                      id={key}
                      readOnly={row?.notEdit}
                      value={value?.[row?.name]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, TechPackChildren.EASE)}
                    />
                  </TableCell>
                ))}
                <TableCell>
                  <div className="flex alignCenter tableAction">
                    {row?.notEdit ? (
                      <img src={edit} alt="edit" onClick={() => handleAllowEditColumn(row?.id, AlterType.EDIT, false)} />
                    ) : (
                      <CustomButton buttonText="Save" className={'button'} handleFunc={() => handleAllowEditColumn(row?.id, AlterType.EDIT, true)} />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    className="flex alignCenter tableAction"
                    onClick={() => {
                      handleDeleteRow(row?.name, row?.id);
                    }}
                  >
                    <button className="button">Delete</button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TechPackTable;
