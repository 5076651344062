import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import mapIcon from '../../assets/images/map-icon.svg';
import L from 'leaflet';

const MapCompo = ({ countryData = [] }: { countryData: any[] }) => {
  //   const countryData = [
  //     { name: 'United States', code: 'US', lat: 36.0344, lng: -114.9705 },
  //     { name: 'India', code: 'IN', lat: 21.9974, lng: 79.0011 },
  //   ];
  const customIcon = L.icon({
    iconUrl: mapIcon,
    iconSize: [40, 40], // Specify the width and height of your icon
  });
  return (
    <div>
      <MapContainer center={[0, 0]} zoom={1} scrollWheelZoom={true} style={{ height: '350px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {countryData?.map(
          (country) =>
            country?.name && (
              <Marker key={country.code} position={[country.lat, country.lng]} icon={customIcon}>
                <Popup>{country.name}</Popup>
              </Marker>
            )
        )}
      </MapContainer>
    </div>
  );
};

export default MapCompo;
