import CircularLoader from 'component/CircularLoader';
import CustomButton from 'component/CustomButton';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import { ROUTES } from 'constants/constants';
import { AuthContext } from 'context/AuthContext';
import { AUTH_TYPE, ToastTypes } from 'enum/enum';
import { AuthContextInterface, ToastInterface } from 'interface/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customScan, customerOnboarding, getCustomersList, getToken } from 'services/authServices';
import { JwtTokenValidate, formatDate, handleErrorMessage, setLocalStorageData } from 'utils/utils';
import styles from './CustomerList.module.scss';
import { ToastContext } from 'context/Toast';
import { Switch } from '@mui/material';
const CustomerList = () => {
  const { isInternalUser, setUserDetails, handleAuth, userDetails, loading, setHasCustomAddon, hasCustomAddon } = useContext(AuthContext) as AuthContextInterface;
  const [customerList, setCustomerList] = useState<Record<string, string>[]>([]);
  const [customerSearchList, setCustomerSearchList] = useState<Record<string, string>[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [searchStore, setSearchStore] = useState<string>('');
  const { callToast } = useContext(ToastContext) as ToastInterface;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchStore(value);
    if (value) {
      const filteredList = customerList?.filter((el: Record<string, string>) => el.name.toLowerCase().includes(value.toLowerCase()));
      setCustomerSearchList(filteredList);
    } else {
      setCustomerSearchList(customerList);
    }
  };

  const fetchCustomersList = async () => {
    setListLoading(true);
    try {
      const res = await getCustomersList();
      setCustomerList(res?.data);
      setCustomerSearchList(res?.data);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setListLoading(false);
    }
  };
  const getStoreToken = async (storeUrl: string) => {
    setButtonLoading(true);
    try {
      const res = await getToken(storeUrl);
      const decodedToken = JwtTokenValidate(res?.data?.token);
      setUserDetails(decodedToken?.properties);
      setLocalStorageData('JWTToken', res?.data?.token);
      try {
        const res = await customScan();
        setLocalStorageData('hasCustomAddon', res?.data?.hasCustomAddon);
        setHasCustomAddon(res?.data?.hasCustomAddon);
      } catch (err) {
        console.log(err);
      }
      handleAuth(AUTH_TYPE.LOGIN);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonLoading(false);
    }
  };

  const handleCustomerOnboard = async (store_url: string, onboarded: boolean) => {
    setListLoading(true);
    try {
      await customerOnboarding(store_url, onboarded);
      fetchCustomersList();
    } catch (error) {
      setListLoading(false);
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    }
  };

  useEffect(() => {
    if (!isInternalUser && !loading) {
      navigate(ROUTES.HOME);
    } else if (isInternalUser && !loading) {
      fetchCustomersList();
    }
  }, [isInternalUser, loading]);

  return listLoading || loading ? (
    <div className="dflex alignCenter justifyCenter w-full">
      <CircularLoader />
    </div>
  ) : (
    <div className={` ${styles.list} flex flexDirectionCol justifySpaceBetween`}>
      {customerList?.length > 0 ? (
        <>
          <div className={` ${styles.list__form} flex justifySpaceBetween`}>
            <h2>
              <span>SWAN</span> Store
            </h2>
            <input placeholder="Search store.." type="text" value={searchStore} onChange={handleOnChange} />
          </div>{' '}
          <div className={` ${styles.list__outer}`}>
            <div className={` ${styles.list__wrap} flex`}>
              {customerSearchList?.length > 0 ? (
                customerSearchList?.map((el: Record<string, string>, index) => (
                  <div className={` ${styles.list__item} ${el?.appInstalled ? '' : styles.isDisabled}`} key={index}>
                    <aside className={el?.storeUrl === userDetails?.store_url ? styles.active : ''}>
                      <h2
                        onClick={() => {
                          getStoreToken(el?.storeUrl);
                        }}
                      >
                        {el?.name}
                      </h2>
                      <div className={`flex alignCenter ${styles.listSwitch}`}>
                        {(el?.onbardedAt || !el?.onbardedAt) && el?.appInstalled && (
                          <Switch
                            checked={el?.onbardedAt ? true : false}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleCustomerOnboard(el?.storeUrl, e.target.checked);
                            }}
                          />
                        )}
                        {el?.onbardedAt && formatDate(el?.onbardedAt)}
                      </div>
                      <span className={` ${styles.storeUrl}`}>{el?.storeUrl}</span>
                      {!el?.appInstalled && <p className={` ${styles.appNotInstalled}`}>App not installed</p>}
                    </aside>
                  </div>
                ))
              ) : (
                <div className="dflex alignCenter justifyCenter w-full">
                  <NoDataPlaceHolder message="No store found in this list" />
                </div>
              )}
            </div>
            <div className={`${styles.list__button} dflex justifyEnd`}>
              <CustomButton
                buttonText={'Next'}
                className="button"
                handleFunc={() => (hasCustomAddon ? navigate(ROUTES.CUSTOM) : navigate(ROUTES.ANALYTICS))}
                disabled={buttonLoading || !userDetails?.store_url}
                loading={buttonLoading}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="dflex alignCenter justifyCenter w-full">
          <NoDataPlaceHolder message="No store found in this list" />
        </div>
      )}
    </div>
  );
};

export default CustomerList;
