import React from 'react';
import styles from './AnalyticCardContainer.module.scss';
interface AnalyticCardContainerProps {
  children: React.ReactNode;
  title: string;
}
const AnalyticCardContainer = ({ title = '', children }: AnalyticCardContainerProps) => {
  return (
    <div className={`${styles.cardContainer}`}>
      <h2>{title}</h2>
      <div> {children}</div>
    </div>
  );
};

export default AnalyticCardContainer;
