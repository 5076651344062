/* eslint-disable max-lines */
import CircularLoader from 'component/CircularLoader';
import { AuthContext } from 'context/AuthContext';
import { DATE_PICKER_VALUE, FETCH_LIST_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import React, { useContext, useEffect, useState } from 'react';
import { fetchList, getProductsSupported } from 'services/listService';
import styles from './Analytics.module.scss';
import PageTitle from 'component/pageTitle/PageTitle';
import { FULFILLED, REACT_APP_POSTHOG_LIVE_PROJECT_ID } from 'constants/constants';
import { removeUnwantedEmails } from 'utils/utils';
import AnalyticsChild from './AnalyticsChild';

const Analytics = () => {
  const [datePickerValue, setDatePickerValue] = useState<DATE_PICKER_VALUE>(DATE_PICKER_VALUE.SEVEN_DAYS);
  const [scanUsersList, setScanUsersList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noOfSizingSuggestions, setNoOfSizingSuggestions] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<any>({});
  const [measurementSuccessData, setMeasurementSuccessData] = useState<any[]>([]);
  const [scanStartedData, setScanStartedData] = useState<any[]>([]);
  const [reScanCount, setReScanCount] = useState<number>(0);
  const [confirmationRate, setConfirmationRate] = useState<any[]>([]);
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;

  const fetchAllList = async () => {
    setLoading(true);
    try {
      const fetchPromisesCombined = [
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after: datePickerValue, event: userDetails?.store_url }),
        getProductsSupported(),
        fetchList({
          projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '',
          after: datePickerValue,
          event: FETCH_LIST_TYPE.SIZING_SUGGESTIONS,
          extraParams: `"store_url":"${userDetails?.store_url}"`,
        }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after: datePickerValue, event: `${userDetails?.store_url}/measurement_success/fit-view` }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after: datePickerValue, event: `${userDetails?.store_url}/scan_started` }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after: datePickerValue, event: `${userDetails?.store_url}/rescan` }),
        fetchList({
          projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '',
          after: datePickerValue,
          event: `${userDetails?.store_url}/token-verify`,
          extraParams: `"status":"success"`,
        }),
      ];

      const resolvedResults = await Promise.allSettled(fetchPromisesCombined);

      resolvedResults?.forEach((result, index) => {
        if (result?.status === FULFILLED) {
          const data: any = result?.value;
          switch (index) {
            case 0: {
              setScanUsersList(removeUnwantedEmails(data));
              break;
            }
            case 1: {
              setProductDetails(data?.data ?? []);
              break;
            }
            case 2: {
              setNoOfSizingSuggestions(data?.length);
              break;
            }
            case 3: {
              setMeasurementSuccessData(data);
              break;
            }
            case 4: {
              setScanStartedData(data);
              break;
            }
            case 5: {
              setReScanCount(data?.length);
              break;
            }
            default: {
              setConfirmationRate(data);
            }
          }
        }
      });
    } catch (err) {
      console.log('error=', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (datePickerValue !== DATE_PICKER_VALUE.CUSTOM) {
      fetchAllList();
    }
  }, [datePickerValue]);

  return (
    <div className={`${styles.listMain}`}>
      {loading && <CircularLoader />}
      {!loading && (
        <>
          <PageTitle name={userDetails?.name} />
          <div className={`${styles.main}`}>
            <div className={`${styles.dateSelect}`}>
              <ul>
                {/* <li>
                    <span>12 Months</span>
                </li> */}

                <li className={datePickerValue === DATE_PICKER_VALUE.SEVEN_DAYS ? styles.active : ''} onClick={() => setDatePickerValue(DATE_PICKER_VALUE.SEVEN_DAYS)}>
                  <span>7 days</span>
                </li>
                <li className={datePickerValue === DATE_PICKER_VALUE.THIRTY_DAYS ? styles.active : ''} onClick={() => setDatePickerValue(DATE_PICKER_VALUE.THIRTY_DAYS)}>
                  <span>30 days</span>
                </li>
                {/* <li>
                    <span>24 hours</span>
                </li> */}
              </ul>
            </div>

            <AnalyticsChild
              usersList={scanUsersList}
              datePickerValue={datePickerValue}
              noOfSizingSuggestions={noOfSizingSuggestions}
              productDetails={productDetails}
              measurementSuccessData={measurementSuccessData}
              scanStartedData={scanStartedData}
              reScanCount={reScanCount}
              confirmationRate={confirmationRate}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Analytics;
