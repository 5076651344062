import React, { useContext, useMemo, useState } from 'react';
import Input from 'component/input/Input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { checkStoreUrl, handleErrorMessage, isValidEmail } from 'utils/utils';
import { Select, ListSubheader, TextField, InputAdornment, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { registerUser } from 'services/authServices';
import CustomButton from 'component/CustomButton';
import { ToastInterface, UserFormData } from 'interface/interface';
import { getCode, getNames } from 'country-list';
import { ToastContext } from 'context/Toast';
import { ToastTypes } from 'enum/enum';
interface SignupProps {
  setRegisterSuccess: (a: boolean) => void;
}
const allOptions = getNames();
const Signup = ({ setRegisterSuccess }: SignupProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(allOptions?.[0]);
  const [searchText, setSearchText] = useState('');
  const { callToast } = useContext(ToastContext) as ToastInterface;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserFormData>({
    defaultValues: {
      name: '',
      email: '',
      store_url: '',
    },
  });
  const displayedOptions = useMemo(() => allOptions.filter((option) => option.toLowerCase().includes(searchText.toLowerCase())), [searchText]);
  console.log(errors);

  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    const { email, name, store_url } = data;
    if (!isValidEmail(email.trim())) {
      setError('email', { type: 'custom', message: 'Please enter a valid email' });
    } else if (!name.trim()) {
      setError('name', { type: 'random' });
    } else if (!store_url.trim()) {
      setError('store_url', { type: 'random' });
    } else if (!checkStoreUrl(store_url)) {
      setError('store_url', { type: 'custom', message: 'Please enter a valid store url' });
    } else {
      setLoading(true);
      try {
        const dataRep = { ...data, location: getCode(selectedOption) ?? '' };
        await registerUser(dataRep);
        setRegisterSuccess(true);
      } catch (error: any) {
        callToast(ToastTypes.ERROR, handleErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className={` auth`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Register</h2>
        <h5>Your store not connected with us</h5>
        <p>Please enter the details below</p>
        <Input
          placeholder="Enter your email"
          type="text"
          label="Email"
          register={register}
          inputName="email"
          errorMessage={errors.email ? (errors.email.message ? errors.email.message : 'This Field is required') : ''}
        />
        <Input placeholder="Enter store Name" type="text" label="Store name" register={register} inputName="name" errorMessage={errors.name && 'This Field is required '} />
        <Input
          placeholder="e.g. swan.myshopify.com"
          type="text"
          label="Store url"
          register={register}
          inputName="store_url"
          errorMessage={errors.store_url ? (errors.store_url.message ? errors.store_url.message : 'This Field is required') : ''}
        />
        {/* <Input placeholder="Enter location" type="text" label="Location" register={register} inputName="location" errorMessage={errors.location && 'This Field is required '} /> */}
        <div className="customSelect">
          <label>Location</label>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            fullWidth
            labelId="search-select-label"
            id="search-select"
            value={selectedOption}
            // label="Options"
            onChange={(e) => setSelectedOption(e.target.value)}
            onClose={() => setSearchText('')}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => selectedOption}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions?.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>

        {/* <Input placeholder="******" type="password" label="Password" register={register} inputName="password" errorMessage={errors.password && 'This Field is required '} /> */}
        {/* <Input
            placeholder="******"
            type="password"
            label="Confirm Password"
            register={register}
            inputName="confirmPassword"
            errorMessage={errors.confirmPassword ? (errors.confirmPassword.message ? errors.confirmPassword.message : 'This Field is required') : ''}
          /> */}
        {/* <div className={`dflex justifySpaceBetween alignCenter auth__footer`}>
            <div>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Remember for 30 days" />
            </div>
          </div> */}

        <CustomButton type="submit" buttonText="Register" className="button full" loading={loading} disabled={loading} />
      </form>
    </div>
  );
};

export default Signup;
