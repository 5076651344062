import AnalyticsCard from 'component/analyticsCard/AnalyticsCard';
import { Metrics } from 'enum/enum';
import React from 'react';
import { kpiCalculation } from 'utils/utils';
import styles from './AnalyticSizingCards.module.scss';
import { AnalyticCardsProps } from 'interface/interface';

const AnalyticSizingCards = ({ scanStartedData, measurementSuccessData, datePickerValue, confirmationRate, reScanCount }: AnalyticCardsProps) => {
  return (
    <div className={`${styles.cardList} dflex`}>
      <div className={`${styles.card}`}>
        <AnalyticsCard
          title={Metrics.SCAN_COMPLETE_RATE}
          value={kpiCalculation(measurementSuccessData?.length, scanStartedData?.length, true)}
          data={measurementSuccessData}
          datePickerValue={datePickerValue}
          showGraph
        />
      </div>
      <div className={`${styles.card}`}>
        <AnalyticsCard
          title={Metrics.SCAN_ACCURACY}
          value={kpiCalculation(measurementSuccessData?.length, scanStartedData?.length, true)}
          data={measurementSuccessData}
          datePickerValue={datePickerValue}
          showGraph
        />
      </div>
      <div className={`${styles.card}`}>
        <AnalyticsCard
          title={Metrics.CAMERA_ACTIVATION_RATE}
          value={kpiCalculation(scanStartedData?.length, scanStartedData?.length + reScanCount, true)}
          data={scanStartedData}
          datePickerValue={datePickerValue}
          showGraph
        />
      </div>
      <div className={`${styles.card}`}>
        <AnalyticsCard
          title={Metrics.COMPLETION_AND_CONFIRMATION_RATE}
          value={kpiCalculation(confirmationRate?.length, scanStartedData?.length, true)}
          data={confirmationRate}
          datePickerValue={datePickerValue}
          showGraph
        />
      </div>
    </div>
  );
};

export default AnalyticSizingCards;
