import React, { useEffect, useState } from 'react';
import upload from 'assets/images/upload-cloud.svg';
import styles from './FileUpload.module.scss';
const FileUpload = ({ handleFileUploaded }: { handleFileUploaded: (file: File[]) => void }) => {
  const [selectedFile, setSelectedFile] = useState<any[]>();
  const handledFileUpload = () => {
    if (selectedFile) {
      handleFileUploaded(Array.from(selectedFile));
      clearFileInput();
    } else {
      // Handle the case where no file is selected (optional)
      console.log('No file selected');
    }
  };
  useEffect(() => {
    if (selectedFile) {
      handledFileUpload();
    }
  }, [selectedFile]);
  function handleDragOver(event: any) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Display a "copy" cursor when dragging over
  }
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileUploaded(Array.from(files));
    clearFileInput();
  };
  function clearFileInput() {
    const fileInput: any = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = ''; // Clear the value of the file input
    }
  }

  return (
    <div className={`fileUploadMain ${styles.upload}`} onDrag={handleDragOver} onDrop={handleDrop}>
      <input
        id="fileInput"
        type="file"
        multiple
        onChange={(e: any) => {
          setSelectedFile(e.target.files || null);
        }}
      />
      <img src={upload} alt="upload" />
      <p>
        <span>Click to upload</span> or drag and drop
      </p>
    </div>
  );
};

export default FileUpload;
