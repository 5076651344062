import React, { useContext } from 'react';
import styles from './Sidebar.module.scss';
import logoWhite from 'assets/images/logo-white-all.svg';
import logOut from 'assets/images/log-out.svg';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from 'constants/constants';
import { AuthContextInterface } from 'interface/interface';
import { AuthContext } from 'context/AuthContext';
import { AUTH_TYPE } from 'enum/enum';

const Sidebar = () => {
  const { handleAuth, userDetails, isInternalUser, hasCustomAddon } = useContext(AuthContext) as AuthContextInterface;
  return (
    <div className={`sidebar ${styles.sidebar}`}>
      <div>
        <div className={`dflex justifySpaceBetween alignCenter ${styles.logo}`}>
          <img src={logoWhite} alt="Swam" />
        </div>
        <ul>
          {hasCustomAddon ? (
            <>
              <NavLink to={ROUTES.CUSTOM}>
                {({ isActive }) => (
                  <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                    <span>Custom</span>
                  </li>
                )}
              </NavLink>
            </>
          ) : (
            <>
              {' '}
              <NavLink to={ROUTES.ANALYTICS}>
                {({ isActive }) => (
                  <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                    <span>Analytics</span>
                  </li>
                )}
              </NavLink>{' '}
              <NavLink to={ROUTES.MEASUREMENTS}>
                {({ isActive }) => (
                  <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                    <span>Measurements</span>
                  </li>
                )}
              </NavLink>
              {isInternalUser && (
                <NavLink to={ROUTES.RESOURCES}>
                  {({ isActive }) => (
                    <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                      <span>Resources</span>
                    </li>
                  )}
                </NavLink>
              )}
              <NavLink to={ROUTES.REFERRALS}>
                {({ isActive }) => (
                  <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                    <span>Referrals</span>
                  </li>
                )}
              </NavLink>
              {isInternalUser && (
                <NavLink to={ROUTES.KPI_ANALYTICS}>
                  {({ isActive }) => (
                    <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                      <span>Kpi Analytics</span>
                    </li>
                  )}
                </NavLink>
              )}
            </>
          )}
          {/* {!hasCustomAddon && (
            <NavLink to={ROUTES.RESOURCES}>
              {({ isActive }) => (
                <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                  <span>Resources</span>
                </li>
              )}
            </NavLink>
          )}
          {!hasCustomAddon && (
            <NavLink to={ROUTES.REFERRALS}>
              {({ isActive }) => (
                <li className={` ${isActive ? styles.active : ''}`} role="cursor">
                  <span>Referrals</span>
                </li>
              )}
            </NavLink>
          )} */}
          {/* <Link to={ROUTES.PROJECTS}>
            <li>
              <span className={` ${pathname === ROUTES.PROJECTS ? styles.active : ''}`} role="cursor">
                {' '}
                <img src={project} alt="Projects" /> Projects{' '}
              </span>
            </li>
          </Link> */}
          {/* <Link to={ROUTES.PROJECTS}>
            <li className={` ${pathname === ROUTES.PROJECTS ? styles.active : ''}`} role="cursor">
              <span>
                {' '}
                <img src={project} alt="Projects" /> Live garments{' '}
              </span>
              <img src={arrowDown} alt="arrow" />
            </li>
          </Link> */}
          {/* <Link to={ROUTES.TASKS}>
            <li>
              <span className={` ${pathname === ROUTES.TASKS ? styles.active : ''}`} role="cursor">
                {' '}
                <img src={task} alt="Tasks" /> Tasks{' '}
              </span>
            </li>
          </Link> */}
          {/* <Link to={ROUTES.REPORTING}>
            <li>
              <span className={` ${pathname === ROUTES.REPORTING ? styles.active : ''}`} role="cursor">
                {' '}
                <img src={pieChart} alt="Reporting" /> Reporting{' '}
              </span>
            </li>
          </Link>
          <Link to={ROUTES.USERS}>
            <li>
              <span className={` ${pathname === ROUTES.USERS ? styles.active : ''}`} role="cursor">
                {' '}
                <img src={user} alt="Users" /> Users{' '}
              </span>
            </li>
          </Link> */}
          {/* <Link to={ROUTES.SUPPORT}>
            <li className={` ${pathname === ROUTES.SUPPORT ? styles.active : ''}`} role="cursor">
              <span>
                {' '}
                <img src={support} alt="Support" /> Support{' '}
              </span>
            </li>
          </Link> */}
        </ul>
      </div>
      <div>
        <ul>
          {/* <Link to={ROUTES.SUPPORT}>
            <li className={` ${pathname === ROUTES.SUPPORT ? styles.active : ''}`} role="cursor">
              <span>
                {' '}
                <img src={support} alt="Support" /> Support{' '}
              </span>
            </li>
          </Link> */}
          {/* <Link to={ROUTES.SETTINGS}>
            <li className={` ${pathname === ROUTES.SETTINGS ? styles.active : ''}`} role="cursor">
              <span>
                {' '}
                <img src={setting} alt="home" /> Settings{' '}
              </span>
            </li>
          </Link> */}
        </ul>
        {/* <div className={`  ${styles.space}`}>
          <div className="flex justifySpaceBetween alignStart">
            <CircularProgressbar value={80} strokeWidth={10} text={`${80}%`} />
            <img role="cursor" src={cross} alt="cross" />
          </div>
          <h5>Used space</h5>
          <p>Your team has used 80% of your available space. Need more?</p>
          <div className={` flex ${styles.links}`}>
            <span role="cursor">Dismiss</span>
            <span role="cursor">Upgrade plan</span>
          </div>
        </div> */}
        <div className={`flex  ${styles.logOutSec}`}>
          {/* <img src={avatar} alt="avatar" /> */}
          <div className={`  ${styles.info}`}>
            <h5>{userDetails?.store_name}</h5>
            {isInternalUser && <Link to={ROUTES.CUSTOMERS_LIST}>Change Store</Link>}
          </div>
          <div className={`  ${styles.logOut}`} onClick={() => handleAuth(AUTH_TYPE.LOGOUT)}>
            <img role="cursor" src={logOut} alt="logOut" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
