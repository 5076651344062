import React, { useContext, useEffect, useRef, useState } from 'react';
import { ModalContentType, PRODUCT_TYPE, SHOW_CASE, TabsValue, ToastTypes, URL_TYPE } from 'enum/enum';
import { getAllProducts, getCollectionsProduct, getListCollections, getTechPackData, onboardProduct, submitTechPackData } from 'services/productServices';
import { ToastContext } from 'context/Toast';
import { ApprovedNotes, AuthContextInterface, FileUploadData, GroupData, TechPackData, ToastInterface } from 'interface/interface';
import {
  addSizesInTechpack,
  getCollectionsOnboardArray,
  getOnboardArray,
  handleErrorMessage,
  modifyCollection,
  modifyGroupsData,
  updateAll,
  updateProductStatus,
} from 'utils/utils';
import { PRODUCTS_DO_NOT_HAVE_SAME_SIZE, SOMETHING_WENT_WRONG } from 'constants/constants';
import MeasurementOnBoarding from 'component/measurementOnboarding/MeasurementOnBoarding';
import styles from './Measurements.module.scss';
import CustomButton from 'component/CustomButton';
import SubHeader from 'component/subHeader/SubHeader';
import { AuthContext } from 'context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { uploadNotes, fileUploadUrlPromiseArray } from 'services/fileUploadServices';
import FileUploadModal from 'component/fileUploadModal/FileUploadModal';
import CustomModal from 'component/CustomModal';
import CreateGroup from 'component/createGroup/CreateGroup';
import { ChildFunction } from './ChildFunction';
import {
  addProductsToGroup,
  createGroup,
  deleteGroup,
  fetchGroupSizes,
  getGroupProducts,
  getGroups,
  groupOnboard,
  modifyGroup,
  removeProductsFromGroup,
} from 'services/groupServices';
import { productsInitialize } from 'services/authServices';

let timerId: NodeJS.Timeout;
let allToggleTimerId: NodeJS.Timeout;
let productsTimerId: NodeJS.Timeout;

const Measurements = () => {
  const [showCase, setShowCase] = useState<SHOW_CASE>(SHOW_CASE.ALL_PRODUCTS);
  const [loading, setLoading] = useState<boolean>(false);
  const [childLoading, setChildLoading] = useState<boolean>(false);
  const [collectionLoading, setCollectionLoading] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [productPageInfo, setProductPageInfo] = useState<Record<string, string>>();
  const [allCollections, setAllCollections] = useState<Record<string, any>>({});
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const { isInternalUser } = useContext(AuthContext) as AuthContextInterface;
  const onBoardedCollectionObj = useRef<Record<string, any>>({});
  const [progress, setProgress] = useState<number>(1);
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [techPackRes, setTechPackRes] = useState<TechPackData>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [files, setFiles] = useState<FileUploadData[]>([]);
  const [showRequirements, setShowRequirements] = useState<boolean>(true);
  const [approvedNotes, setApprovedNotes] = useState<ApprovedNotes>({ notes: '', approved: false, noteId: '' });
  const [multiFiles, setMultiFiles] = useState<FileUploadData[]>([]);
  const productAllToggle = useRef<boolean>(false);
  const [groups, setGroups] = useState<GroupData[]>([]);
  const [modalContentType, setModalContentType] = useState<ModalContentType>(ModalContentType.CREATE);
  const [value, setValue] = useState<string>('1');
  const [groupDetail, setGroupDetail] = useState<GroupData>({ id: '', name: '', expand: false });
  const [selectedProduct, setSelectedProduct] = useState<string[]>([]);
  const storeId = useRef<string>('');
  const storeToggle = useRef<boolean>(false);
  const newGroupId = useRef<string>('');
  const [sizesMatch, setSizesMatch] = useState<boolean>(false);
  const [childButtonDisabled, setChildButtonDisabled] = useState<boolean>(false);
  const filesRef = useRef<FileUploadData[]>([]);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

  const handleProgress = () => {
    productAllToggle.current = false;
    if (showCase === SHOW_CASE.ALL_PRODUCTS && progress === 1) {
      setProgress(2);
      setShowCase(SHOW_CASE.FILE_UPLOAD_TYPE);
      setButtonDisabled(true);
    } else if (showCase === SHOW_CASE.FILE_UPLOAD_TYPE) {
      setShowPopper(true);
      setTimeout(() => {
        setShowPopper(false);
        setProgress(3);
        if (isInternalUser) {
          setShowCase(SHOW_CASE.REVIEW_TABLE);
          fetchGroups();
        }
      }, 2000);
    } else if (showCase === SHOW_CASE.REVIEW_TABLE) {
      setShowCase(SHOW_CASE.NONE);
    }
  };
  const handleBack = () => {
    switch (showCase) {
      case SHOW_CASE.FILE_UPLOAD_TYPE: {
        setShowCase(SHOW_CASE.ALL_PRODUCTS);
        setButtonDisabled(false);
        setProgress(1);
        break;
      }
      case SHOW_CASE.REVIEW_TABLE: {
        setShowCase(SHOW_CASE.FILE_UPLOAD_TYPE);
        if (!files.length) {
          setButtonDisabled(true);
        }
        setProgress(2);
        if (!files.length) {
          setButtonDisabled(true);
        }
      }
    }
  };
  const handleProgressBar = (progress: number) => {
    switch (progress) {
      case 1: {
        setProgress(1);
        setShowCase(SHOW_CASE.ALL_PRODUCTS);
        if (value === TabsValue.FOUR) {
          return;
        }
        if (value === TabsValue.TWO) {
          fetchCollections();
        } else {
          fetchProducts();
        }
        break;
      }
      case 2: {
        setProgress(2);
        setShowCase(SHOW_CASE.FILE_UPLOAD_TYPE);
        if (!files.length) {
          setButtonDisabled(true);
        }
        break;
      }
      case 3: {
        setProgress(3);
        setShowCase(SHOW_CASE.REVIEW_TABLE);
        setButtonDisabled(false);
        fetchGroups();
        break;
      }
      default: {
        setProgress(4);
        setShowCase(SHOW_CASE.NONE);
      }
    }
  };
  const handleShowModal = (contentType = ModalContentType.NONE, Id = '', flag = false) => {
    if (multiFiles?.length) {
      setMultiFiles([]);
      setApprovedNotes({ notes: '', approved: false, noteId: '' });
    }
    if (showCase === SHOW_CASE.ALL_PRODUCTS || showCase === SHOW_CASE.REVIEW_TABLE) {
      if (Id) {
        storeId.current = Id;
      }
      storeToggle.current = flag;
      if (contentType) {
        setModalContentType(contentType);
      }
    }
    setShowModal(!showModal);
  };
  const handleTabsChange = (newValue: string, id = '') => {
    productAllToggle.current = false;
    setAllProducts([]);
    setSelectedProduct([]);
    setAllCollections({});
    if (newValue === TabsValue.ONE) {
      fetchProducts();
    } else if (newValue === TabsValue.TWO) {
      fetchCollections();
    } else if (newValue === TabsValue.THREE) {
      fetchGroups();
    } else {
      const a = groups?.find((el: GroupData) => el?.id === id);
      if (a) {
        setGroupDetail(a);
      }
      fetchGroupProducts(id);
    }
    setValue(newValue);
  };

  const handleFileUploaded = (files: File[]) => {
    setShowRequirements(false);
    const modFiles = files.map((file: File) => {
      const id = uuidv4();
      return {
        file,
        id,
        uploaded: false,
        show: true,
        showProgress: true,
      };
    });
    if (!approvedNotes.notes && !showModal) {
      filesRef.current = [...filesRef.current, ...modFiles];
      setFiles((prev) => [...prev, ...modFiles]);
      UploadSingleFile(modFiles);
    } else {
      const newModFile = files?.map((file: File) => {
        const id = uuidv4();
        return {
          file,
          id,
          uploaded: false,
          show: true,
          showProgress: false,
        };
      });

      filesRef.current = [];
      setMultiFiles((prev) => [...prev, ...newModFile]);
    }
  };

  const fileFilter = (id: string) => {
    const filteredFile = files?.filter((el: any) => el?.id !== id);
    filesRef.current = [];
    setFiles(filteredFile);
  };
  const UploadSingleFile = async (tempFiles: FileUploadData[]) => {
    try {
      const uploadMultiFileRes = await fileUploadUrlPromiseArray(tempFiles);
      const filteredMultipleFile = uploadMultiFileRes?.filter((el: FileUploadData) => el?.uploaded);

      if (filteredMultipleFile?.length !== tempFiles?.length) {
        callToast(ToastTypes.ERROR, SOMETHING_WENT_WRONG);
      }
      setFiles((prev) => {
        uploadMultiFileRes?.forEach((el: FileUploadData) =>
          prev.forEach((file: FileUploadData) => {
            if (file?.id === el?.id) {
              file.uploaded = el?.uploaded;
              file.show = el?.uploaded;
            }
          })
        );
        return prev;
      });
      setButtonDisabled(false);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    }
  };

  const uploadMultipleFiles = async () => {
    setLoading(true);
    const newMultiFiles = multiFiles.map((el) => {
      el.showProgress = true;
      return el;
    });
    setMultiFiles(newMultiFiles);
    try {
      let uploadMultiFileRes;
      if (approvedNotes.notes && showModal) {
        const res = await uploadNotes(approvedNotes);
        uploadMultiFileRes = await fileUploadUrlPromiseArray(newMultiFiles, res?.data?.noteId);
      } else {
        uploadMultiFileRes = await fileUploadUrlPromiseArray(newMultiFiles);
      }
      const filteredMultipleFile = uploadMultiFileRes?.filter((el: FileUploadData) => el?.uploaded);
      if (filteredMultipleFile?.length !== newMultiFiles?.length) {
        callToast(ToastTypes.ERROR, SOMETHING_WENT_WRONG);
      }
      setFiles([...files, ...filteredMultipleFile]);

      setApprovedNotes({ notes: '', approved: false, noteId: '' });
      setTimeout(() => {
        setMultiFiles([]);
        setShowModal(!showModal);
        setButtonDisabled(false);
      }, 500);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleCreateGroup = async ({ groupName = '', overrideSizeMismatch = false }: { groupName?: string; overrideSizeMismatch?: boolean }) => {
    setButtonDisabled(true);
    try {
      if (!newGroupId.current) {
        const res = await createGroup(groupName);
        newGroupId.current = res?.data?.id;
      }
      if (selectedProduct?.length) {
        await addProductsToGroup(newGroupId.current, selectedProduct, overrideSizeMismatch);
        setSelectedProduct([]);
      }
      handleShowModal();
      setValue(TabsValue.THREE);
      await fetchGroups();
      newGroupId.current = '';
    } catch (error: any) {
      if (error?.response?.status === 400 && handleErrorMessage(error) === PRODUCTS_DO_NOT_HAVE_SAME_SIZE) {
        setModalContentType(ModalContentType.OVERRIDE);
      } else {
        callToast(ToastTypes.ERROR, handleErrorMessage(error));
      }
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleTechPackData = async (groupId = '', expand: boolean) => {
    setGroups([...modifyGroupsData(groups, groupId, expand)]);
    if (!expand) {
      return;
    }
    setChildLoading(true);
    try {
      const response = await getTechPackData(groupId);
      if (!Object.keys(response?.data?.sizes)?.length) {
        const res = await fetchGroupSizes(response?.data?.id);
        if (!res?.data?.productSizesMatch) {
          setSizesMatch(res?.data?.productSizesMatch);
          setTechPackRes(response?.data);
          setChildButtonDisabled(true);
          return;
        }
        setSizesMatch(res?.data?.productSizesMatch);
        setTechPackRes(addSizesInTechpack(response?.data, Object.keys(res?.data?.productCountBySize)?.[0]?.split(',') ?? []));
        setChildButtonDisabled(true);
      } else {
        setTechPackRes(response?.data);
      }
    } catch (error: any) {
      setChildButtonDisabled(true);
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildLoading(false);
    }
  };
  const handleTechPackSubmit = async () => {
    setChildButtonDisabled(true);
    try {
      if (techPackRes) {
        await submitTechPackData(techPackRes);
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };
  const fetchGroups = async () => {
    setLoading(true);
    try {
      const res = await getGroups();
      const sorted = res?.data.sort((a: GroupData, b: GroupData) => {
        return Number(b.hasmeasurements) - Number(a.hasmeasurements);
      });
      setGroups(modifyGroupsData(sorted));
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAuthenticate = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCase(SHOW_CASE.LOAD_PRODUCT);
      setLoading(false);
    }, 1000);
  };
  const fetchProducts = async (requestUrl = '', type = URL_TYPE.NEXT) => {
    setLoading(true);
    try {
      const res = await getAllProducts(requestUrl, type);
      const data = res?.data?.products;
      setButtonDisabled(false);
      setShowCase(SHOW_CASE.ALL_PRODUCTS);
      setAllProducts((prev) => [...prev, ...data]);
      setProductPageInfo({ ...res?.data?.pageInfo });
      if (!groups.length) {
        await fetchGroups();
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleProductOnBoard = async (onboarded: boolean, shopifyProductId: string, type: PRODUCT_TYPE, collectionId = '') => {
    setChildButtonDisabled(true);
    try {
      await onboardProduct([{ shopifyProductId, onboarded }]);
      if (type === PRODUCT_TYPE.PRODUCTS) {
        setAllProducts(updateProductStatus(allProducts, onboarded, shopifyProductId));
      } else {
        const data = updateProductStatus(allCollections?.[collectionId]?.child, onboarded, shopifyProductId);
        allCollections[collectionId].child = data;
        setAllCollections({ ...allCollections });
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };

  const fetchCollections = async (requestUrl = '', type = URL_TYPE.NEXT) => {
    setCollectionLoading(true);
    try {
      const collections = await getListCollections(requestUrl, type);
      const collectionData: any = {};
      collections?.map((el: any) => {
        collectionData[el?.collectionId] = { value: el, child: [], toggleAll: false, expand: false };
      });
      setAllCollections(collectionData);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setCollectionLoading(false);
    }
  };
  const fetchCollectionProducts = async ({ collectionId = '', requestUrl = '', expand }: { collectionId: string; requestUrl?: string; expand?: boolean }) => {
    setSelectedProduct([]);
    if (!requestUrl) {
      setAllCollections({ ...modifyCollection(allCollections, collectionId, expand) });
    }
    if (!expand) {
      return;
    }
    setChildLoading(true);
    try {
      const res = await getCollectionsProduct(collectionId, requestUrl);
      const data = res?.data?.products;
      const prevData = allCollections[collectionId].child;
      allCollections[collectionId].child = [...prevData, ...data];
      setAllCollections({ ...allCollections });
      setProductPageInfo({ ...res?.data?.pageInfo });
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildLoading(false);
    }
  };

  const handleToggleAll = async (flag: boolean, type: PRODUCT_TYPE, colId = '') => {
    setChildButtonDisabled(true);
    let onBoardProductArray: any;
    if (type === PRODUCT_TYPE.PRODUCTS) {
      onBoardProductArray = getOnboardArray(allProducts, flag);
    } else {
      onBoardProductArray = getCollectionsOnboardArray(onBoardedCollectionObj.current);
    }
    try {
      await onboardProduct(onBoardProductArray);
      if (type === PRODUCT_TYPE.PRODUCTS) {
        productAllToggle.current = flag;
        setAllProducts([...updateAll(allProducts, flag)]);
      } else {
        allCollections[colId].toggleAll = flag;
        const data = updateAll(allCollections[colId]?.child, flag);
        allCollections[colId].child = data;
        setAllCollections({ ...allCollections });
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };
  const handleOnboardGroup = async () => {
    setButtonDisabled(true);
    productAllToggle.current = storeToggle.current;
    try {
      await groupOnboard(storeId.current, storeToggle.current);
      setShowModal(!showModal);
      await fetchGroups();
    } catch (error: any) {
      productAllToggle.current = !productAllToggle.current;
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };

  const fetchGroupProducts = async (id: string, requestUrl = '', type = URL_TYPE.NEXT) => {
    setLoading(true);
    try {
      const res = await getGroupProducts(id, requestUrl, type);
      const data = res?.data?.products;
      setAllProducts((prev) => [...prev, ...data]);
      setProductPageInfo({ ...res?.data?.pageInfo });
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteGroup = async () => {
    setButtonDisabled(true);
    try {
      await deleteGroup(storeId.current);
      handleShowModal();
      setValue(TabsValue.THREE);
      await fetchGroups();
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleModifyGroup = async (name: string) => {
    setButtonDisabled(true);
    try {
      await modifyGroup({ id: storeId.current, name });
      const modifyGroupArray = groups?.map((el: GroupData) => {
        if (el?.id === storeId?.current) {
          el.name = name;
        }
        return el;
      });
      setGroups(modifyGroupArray);
      setGroupDetail({ id: storeId.current, name });
      const a = allProducts?.map((el: any) => {
        el.techpackName = name;
        return el;
      });
      setAllProducts(a);
      setShowModal(!showModal);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleRemoveProductsFromGroup = async () => {
    setButtonDisabled(true);
    try {
      await removeProductsFromGroup(value, selectedProduct);
      const filteredProducts = allProducts?.filter((el: any) => !selectedProduct?.some((id: string) => id === el?.shopifyProductId));
      setAllProducts(filteredProducts);
      setSelectedProduct([]);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleAddProductsToGroup = async (id: string) => {
    setButtonDisabled(true);
    try {
      await addProductsToGroup(id, [storeId.current], false);
      storeId.current = '';
      handleShowModal();
      setValue(TabsValue.THREE);
      await fetchGroups();
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleProductsInitialize = async () => {
    setRefreshLoading(true);
    try {
      await productsInitialize();
      handleTabsChange(value);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setRefreshLoading(false);
    }
  };
  useEffect(() => {
    fetchProducts();
    return () => {
      clearTimeout(allToggleTimerId);
      clearTimeout(timerId);
      clearTimeout(productsTimerId);
    };
  }, []);

  return (
    <>
      {
        <div className={`dflex ${styles.onboarding}`}>
          <div className={` ${styles.onboarding__left}`}>
            <div className={styles.onboarding__scrollable}>
              <SubHeader showCase={showCase} groups={groups} handleProductsInitialize={handleProductsInitialize} refreshLoading={refreshLoading} />
              <div className={` ${styles.flexWrapper}`}>
                {
                  <ChildFunction
                    showCase={showCase}
                    handleAuthenticate={handleAuthenticate}
                    loading={loading}
                    fetchGroups={fetchGroups}
                    fetchProducts={fetchProducts}
                    allProducts={allProducts}
                    productPageInfo={productPageInfo}
                    handleProductOnBoard={handleProductOnBoard}
                    fetchCollections={fetchCollections}
                    allCollections={allCollections}
                    setAllProducts={setAllProducts}
                    setAllCollections={setAllCollections}
                    handleToggleAll={handleToggleAll}
                    setShowRequirements={setShowRequirements}
                    showPopper={showPopper}
                    handleShowModal={handleShowModal}
                    showRequirements={showRequirements}
                    handleFileUploaded={handleFileUploaded}
                    files={files}
                    fileFilter={fileFilter}
                    techPackRes={techPackRes}
                    setTechPackRes={setTechPackRes}
                    childLoading={childLoading}
                    productAllToggle={productAllToggle.current}
                    groups={groups}
                    fetchGroupProducts={fetchGroupProducts}
                    buttonDisabled={!showModal && buttonDisabled}
                    value={value}
                    setValue={setValue}
                    handleTabsChange={handleTabsChange}
                    groupDetail={groupDetail}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    handleRemoveProductsFromGroup={handleRemoveProductsFromGroup}
                    handleTechPackData={handleTechPackData}
                    sizesMatch={sizesMatch}
                    handleTechPackSubmit={handleTechPackSubmit}
                    // groupProducts={groupProducts}
                    setButtonDisabled={setButtonDisabled}
                    setChildButtonDisabled={setChildButtonDisabled}
                    childButtonDisabled={childButtonDisabled}
                    fetchCollectionProducts={fetchCollectionProducts}
                    collectionLoading={collectionLoading}
                  />
                }
              </div>
              <div className={`  ${showCase !== SHOW_CASE.ALL_PRODUCTS ? `justifySpaceBetween` : `justifyEnd`}  ${styles.footerNext}`}>
                {showCase !== SHOW_CASE.NONE && (
                  <>
                    {showCase !== SHOW_CASE.ALL_PRODUCTS && <CustomButton buttonText={'Back'} className={'button'} handleFunc={handleBack} />}
                    <CustomButton
                      buttonText={showCase === SHOW_CASE.FILE_UPLOAD_TYPE ? 'Submit' : 'Next'}
                      disabled={buttonDisabled}
                      className={'button'}
                      handleFunc={handleProgress}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={` ${styles.onboarding__right}`}>
            <MeasurementOnBoarding progress={progress} handleProgressBar={handleProgressBar} isInternalUser={isInternalUser} />
          </div>

          {showCase === SHOW_CASE.FILE_UPLOAD_TYPE ? (
            <CustomModal showModal={showModal} handleShowModal={handleShowModal}>
              <FileUploadModal
                handledFileUploaded={handleFileUploaded}
                handleShowModal={handleShowModal}
                files={multiFiles}
                fileFilter={fileFilter}
                approvedNotes={approvedNotes}
                setApprovedNotes={setApprovedNotes}
                uploadMultipleFiles={uploadMultipleFiles}
                loading={loading}
              />
            </CustomModal>
          ) : (
            <CustomModal maxWidth={'464px'} showModal={showModal} handleShowModal={handleShowModal}>
              <CreateGroup
                handleShowModal={handleShowModal}
                handleCreateGroup={handleCreateGroup}
                buttonDisabled={buttonDisabled}
                modalContentType={modalContentType}
                groups={groups}
                handleDeleteGroup={handleDeleteGroup}
                handleOnboardGroup={handleOnboardGroup}
                handleAddProductsToGroup={handleAddProductsToGroup}
                handleModifyGroup={handleModifyGroup}
                storeToggle={storeToggle.current}
              />
            </CustomModal>
          )}
        </div>
      }
    </>
  );
};

export default Measurements;
