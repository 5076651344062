// import { DATE_PICKER, DATE_PICKER_VALUE } from 'enum/enum';
import { SOMETHING_WENT_WRONG, emailRegex, excludeEmails, secondEmailRegex } from 'constants/constants';
import { GroupData, RowsData, TechPackData } from 'interface/interface';
import jwtDecode from 'jwt-decode';
import { v4 as uuid } from 'uuid';
export const isValidEmail = (email: string): boolean => {
  const checkEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return checkEmailValidation.test(email);
};

export function getLocalStorageData(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '');
  } catch {
    return null;
  }
}

export function setLocalStorageData(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export function JwtTokenValidate(jwtToken = '') {
  const token = jwtToken || getLocalStorageData('JWTToken');

  if (token) {
    try {
      const decodedToken: any = jwtDecode(token);
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      }
      return decodedToken;
    } catch (err) {
      console.log(err);
    }
  }
}
export const checkHttpsInURL = (url: string) => {
  if (url.includes('https')) {
    return url.split('/')[2];
  } else {
    return url;
  }
};

export function getDate(date: Date) {
  // Get the current date
  const currentDate = new Date(date);
  // Get the year, month, and day
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const day = currentDate.getDate();
  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  return formattedDate;
}

export const removeUnwantedEmails = (data: any[]) => {
  return data?.filter(
    (el) => !emailRegex.test(el?.properties?.email) && !secondEmailRegex.test(el?.properties?.email) && !excludeEmails.includes(el?.properties?.email) && el?.person
  );
};

// export const getFilteredSizingSuggestionListLength = (data: any[], store_url: string) => {
//   return data?.filter((item: any) => {
//     let res = false;
//     if (store_url === 'bloquv.myshopify.com' && new Date(item?.timestamp.split('T')[0]) < new Date('2024-03-06')) {
//       return false;
//     }
//     const keys = Object.keys(item.properties);
//     keys.forEach((key) => {
//       if (item.properties[key]['store_url']) {
//         res = item.properties[key].store_url === store_url;
//         return;
//       }
//     });
//     return res;
//   }).length;
// };

export const getXAxisPoints = (data: any[]) => {
  const xaxisPointsObject: any = {};
  let maxi = 0;
  data?.forEach((el) => {
    if (xaxisPointsObject[el?.timestamp?.split('T')[0]]) {
      xaxisPointsObject[el?.timestamp?.split('T')[0]]++;
    } else {
      xaxisPointsObject[el?.timestamp?.split('T')[0]] = 1;
    }
    if (maxi < xaxisPointsObject[el?.timestamp?.split('T')[0]]) {
      maxi = xaxisPointsObject[el?.timestamp?.split('T')[0]];
    }
  });

  return [xaxisPointsObject, maxi];
};

export const createCountryData = (data: any[]) => {
  const countryNameObj: Record<string, any> = {};
  let countryData: any[] = data?.reduce((ac: any[], el: any) => {
    const conName = el?.properties?.countryName ?? el?.properties?.$geoip_country_name;
    if (conName in countryNameObj) {
      countryNameObj[conName]++;
      return ac;
    } else countryNameObj[conName] = 1;
    const obj = {
      name: el?.properties?.countryName ?? el?.properties?.$geoip_country_name ?? '',
      code: el?.properties?.countryCode ?? el?.properties?.$geoip_country_code ?? '',
      lat: el?.properties?.latitude ?? el?.properties?.$geoip_latitude ?? 0,
      lng: el?.properties?.longitude ?? el?.properties?.$geoip_longitude ?? 0,
    };
    ac.push(obj);
    return ac;
  }, []);

  countryData = countryData.map((el) => {
    return (el = { ...el, value: countryNameObj[el?.name] });
  });
  return countryData;
};
export const getPercentageArray = (data: any[]) => {
  const total = data.reduce((ac: number, el: any) => {
    return ac + el?.value;
  }, 0);

  const percentageArray = data.map((el) => {
    return (el = { ...el, percentageVal: Math.round((el?.value / total) * 100), id: uuid() });
  });
  return { total, percentageArray };
};

export function formatNumber(value: number) {
  if (!value) return 0;

  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'K';
  } else {
    return value.toString();
  }
}
export const updateProductStatus = (data: any[], onboarded: boolean, shopifyProductId: string) => {
  return data?.map((el: any) => {
    if (el?.shopifyProductId === shopifyProductId) {
      el.isOnboarded = onboarded;
    }
    return el;
  });
};

export const updateAll = (data: any[], flag: boolean) => {
  return data?.map((el: any) => {
    if (el?.sizes?.length > 0) {
      el.isOnboarded = flag;
    }
    return el;
  });
};

export const getOnboardArray = (data: any[], flag: boolean) => {
  return data?.reduce((ac: any[], el: any) => {
    if (el?.sizes?.length > 0) {
      ac.push({
        shopifyProductId: el?.shopifyProductId,
        onboarded: flag,
      });
    }
    return ac;
  }, []);
};
export const getCollectionsOnboardArray = (data: any) => {
  const onboardArray: any[] = [];
  for (const key in data) {
    if (Array.isArray(data[key])) {
      onboardArray.push(...data[key]);
    }
  }
  return onboardArray.reduce((ac: any[], el: any) => {
    if (el?.sizes?.length > 0) {
      ac.push({
        shopifyProductId: el?.shopifyProductId,
        onboarded: el?.isOnboarded,
      });
    }
    return ac;
  }, []);
};
export const getCalculation = (data: any, index: number, type: string) => {
  const a = data[index][1][type];
  const b = data[index - 1][1][type];
  const sum = a - b;
  if (isNaN(sum)) {
    return +0;
  }
  if (sum < 0) {
    return toFixedNumber(sum);
  } else {
    return `+${toFixedNumber(sum)}`;
  }
};

export const getRowsAndAllowColumns = (data: TechPackData) => {
  const value = Object.values(data?.sizes)?.[0];
  return (
    Object.keys(value)
      .filter((key) => key !== 'id')
      .map((key) => ({ name: key, id: uuid(), notEdit: true })) ?? []
  );
};
export const updateTableRows = (data: RowsData[], obj: Record<string, boolean>) => {
  Object.keys(obj).forEach((el) => {
    const a = { name: el, id: uuid(), notEdit: true };
    data.push(a);
  });
  return data;
};
export const filterRowOptions = (optionsData: Record<string, any>[], selectedData: RowsData[]) => {
  return optionsData?.map((el: Record<string, any>) => {
    if (selectedData?.some((row: RowsData) => row?.name === el?.name)) {
      el.selected = true;
    }
    return el;
  });
};
export const updateTechPackData = (data: TechPackData, obj: Record<string, boolean>) => {
  Object.keys(obj).forEach((element: string) => {
    Object.keys(data?.ease).forEach((el: string) => {
      data.ease[el][element] = 0;
    });
    Object.keys(data?.sizes).forEach((el: string) => {
      data.sizes[el][element] = 0;
    });
  });
  return data;
};

export const handlePromiseArray = async (promiseArray: any[]) => {
  return Promise.allSettled(promiseArray);
};

export const handleErrorMessage = (error: any) => {
  return error?.response?.data?.error ?? error?.response?.data?.message ?? error?.response?.data?.details?.[0]?.message ?? SOMETHING_WENT_WRONG;
};
export const addSizesInTechpack = (techpack: TechPackData, sizes: any[]) => {
  sizes?.map((el: any) => {
    techpack.sizes[el] = {};
  });

  return techpack;
};

export const modifyCollection = (collections: any, id = '', expand = false) => {
  for (const key in collections) {
    if (key === id) {
      collections[key].expand = expand;
    } else {
      collections[key].expand = false;
    }
    collections[key].child = [];
  }
  return collections;
};
export const checkStoreUrl = (storeUrl: string) => {
  const regex = /\.myshopify\.com/;
  return regex.test(storeUrl);
};

export const toFixedNumber = (num: number) => {
  if (!num) {
    return 0;
  }
  num = Number(num);
  if (num % 1 === 0) {
    return num;
  } else {
    return Number(num.toFixed(3));
  }
};

export const deepCopy = (data: any) => {
  if (!data) {
    return data;
  }
  return JSON.parse(JSON.stringify(data));
};

export const modifyGroupsData = (groups: GroupData[], id = '', expand = false) => {
  if (id) {
    return groups?.map((el: GroupData) => {
      if (el?.id === id) {
        el.expand = expand;
      } else {
        el.expand = false;
      }
      return el;
    });
  }
  return groups?.map((el: { id: string; name: string }) => {
    const a = { ...el, expand: false };
    return a;
  });
};

export function formatDate(timestamp: string) {
  const date = new Date(timestamp);
  const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const formattedDate = `${monthAbbreviation}-${day}-${year}`;
  return formattedDate;
}

export const modifyStoreUrl = (str: string) => {
  const regex = /\.myshopify\.com/;
  if (regex.test(str)) {
    return str;
  }
  return `${str}.myshopify.com`;
};
let id: NodeJS.Timeout;
export const copyToClipboard = (text: string) => {
  clearTimeout(id);
  navigator.clipboard.writeText(text).then(
    function () {
      const button = document.getElementById('copy-button');
      if (button) {
        button.innerText = 'Copied to clipboard';
        id = setTimeout(() => {
          button.innerText = 'Copy to clipboard';
        }, 2000);
      }
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
};
export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dateFormat(inputDate: string): string {
  const date = new Date(inputDate);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
}

export const handleDownloadBtn = () => {
  const link = document.createElement('a');
  link.href = 'https://dngrx2mvm8119.cloudfront.net/Manual Measurements - Swan Custom Calibration.xlsx';
  link.download = 'Manual Measurements - Swan Custom Calibration.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const kpiCalculation = (numerator = 0, denominator = 1, percentageType = false) => {
  if (!numerator || !denominator) {
    return '-';
  }
  const result = numerator / denominator || 0;
  if (result && result !== Infinity) {
    return percentageType ? `${Math.round(result * 100)}%` : `${Math.round(result)}`;
  }
  return percentageType ? `${0}%` : `${0}`;
};
export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
