import { API_END_POINTS } from 'constants/constants';
import { TOKEN_DATA_TYPE } from 'enum/enum';
import UseApiService from './apiService';

export const submitNewReferral = (data: Record<string, string>) => {
  const body = {
    url: API_END_POINTS.REFERRAL,
    data,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};
