import React from 'react';
import styles from './ResourcesCard.module.scss';
import arrowUp from '../../assets/images/arrow-up-right.svg';

const ResourcesCard = ({ resource }: { resource: any }) => {
  return (
    <div className={` ${styles.resourcesCard}`}>
      <a>
        <figure>
          <h2>{resource.title}</h2>
        </figure>
        <figcaption>
          <header className="dflex alignCenter justifySpaceBetween">
            <h3>{resource.header}</h3>
            <img src={arrowUp} alt="arrow" />
          </header>
          <p>{resource.description}</p>
        </figcaption>
      </a>
    </div>
  );
};

export default ResourcesCard;
