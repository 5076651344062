import React from 'react';
import ToastContextProvider from './Toast';
import AuthContextProvider from './AuthContext';
export function ContextWrapper({ children }: { children: React.ReactNode }) {
  return (
    <ToastContextProvider>
      <AuthContextProvider>{children}</AuthContextProvider>
    </ToastContextProvider>
  );
}
