import React from 'react';
import styles from './MeasurementOnBoarding.module.scss';
import CustomButton from 'component/CustomButton';
interface MeasurementOnBoardingProps {
  setValue?: (a: number) => void;
  handleProgressBar: (e: number) => void;
  progress: number;
  isInternalUser: boolean;
}

const MeasurementOnBoarding = ({ progress = 0, handleProgressBar, isInternalUser }: MeasurementOnBoardingProps) => {
  const handleEmailButtonClick = () => {
    const email = 'neal@getswan.co';
    const subject = 'Swan Dashboard query';

    // Construct the mailto URL
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    // Open the default email client
    window.location.href = mailtoUrl;
  };
  return (
    <>
      <div className={`${styles.main} ${isInternalUser ? '' : styles.isDisabled}`}>
        <div className={`${styles.main__top}`}>
          <h2>
            Onboarding <span className="progress">In progress</span>
          </h2>
          <p>On your way to more sales and fewer returns!</p>
          <ul>
            <li className={`${styles.active}`}>
              <p
                onClick={() => {
                  handleProgressBar(1);
                }}
              >
                <span>1</span>
                Load your store
              </p>
            </li>
            <li className={progress > 1 ? styles.active : ''}>
              <p
                onClick={() => {
                  handleProgressBar(2);
                }}
              >
                <span>2</span>
                Upload measurements
              </p>
            </li>
            <li className={progress > 2 ? styles.active : ''}>
              <p
                onClick={() => {
                  handleProgressBar(3);
                }}
              >
                <span>3</span>
                Review
              </p>
            </li>
            <li className={progress > 3 ? styles.active : ''}>
              <p
                onClick={() => {
                  handleProgressBar(4);
                }}
              >
                <span>4</span>
                Go Live
              </p>
            </li>
          </ul>
        </div>
        <div className={`${styles.main__bottom}`}>
          <h5>Having trouble?</h5>
          <p>We are here to help you through the process.</p>
          <CustomButton type="submit" buttonText="Contact us" className="button outline black" handleFunc={handleEmailButtonClick} />
        </div>
      </div>
    </>
  );
};

export default MeasurementOnBoarding;
