import React, { useContext, useState } from 'react';
import Input from 'component/input/Input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { handleErrorMessage, isValidEmail } from 'utils/utils';
// import { ROUTES } from 'constants/constants';
import { authenticateUser } from 'services/authServices';
import message from 'assets/images/message.svg';
import arrowLeft from 'assets/images/arrow-left-gray.svg';
import CustomButton from 'component/CustomButton';
import singUp from 'assets/images/singUp.svg';
import { ToastContext } from 'context/Toast';
import { ToastTypes } from 'enum/enum';
import { ToastInterface } from 'interface/interface';
import { ROUTES } from 'constants/constants';
interface Inputs {
  email: string;
}
const Login = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });
  const handleUserRegister = async (showToast = false) => {
    setLoading(true);
    try {
      const redirectUrl = `${ROUTES.USER_VERIFY}/?auth_type=dashboard_login`;
      await authenticateUser(getValues('email'), redirectUrl);
      if (showToast) {
        callToast(ToastTypes.SUCCESS, 'Successfully email sent');
      } else {
        setShowMessage(true);
      }
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { email } = data;
    if (!isValidEmail(email.trim())) {
      setError('email', { type: 'custom', message: 'Please enter a valid email' });
    } else {
      handleUserRegister();
    }
  };
  return (
    <>
      {showMessage ? (
        <div className="messagePage">
          <span className="messagePage__icon">
            <img src={message} alt="Swan" />
          </span>
          <div>
            <h2>Check your email</h2>
            <p>
              {`We've sent a magic link to`} <br />
              <strong>{getValues('email')}</strong>{' '}
            </p>
            <a href="https://mail.google.com" target="_blank" rel="noreferrer">
              <CustomButton buttonText="Open email app" className="button full" />
            </a>

            <p className="messagePage__email">
              Didn’t receive the email?{' '}
              <span className={loading ? 'disabled-link' : ''} onClick={() => handleUserRegister(true)}>
                Click to resend
              </span>
            </p>
            <p className="messagePage__back" onClick={() => setShowMessage(!showMessage)}>
              {' '}
              <img src={arrowLeft} alt="Swan" /> Back to log in
            </p>
          </div>
        </div>
      ) : (
        <div className={` auth`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={singUp} alt="Swam" className={` logo`} />
            <h2>Log in to your account</h2>
            <p>Welcome back! </p>
            <Input
              placeholder="Enter your email"
              type="text"
              label="Email"
              register={register}
              inputName="email"
              errorMessage={errors.email ? (errors.email.message ? errors.email.message : 'This Field is required') : ''}
            />
            <CustomButton type="submit" buttonText="Sign in" className="button full lg" loading={loading} disabled={loading} />
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
