import React from 'react';
import noData from 'assets/images/no-data.svg';

const NoDataPlaceHolder = ({ message = '', children, title = 'No data found', imgUrl = noData }: { message?: string; children?: JSX.Element; title?: string; imgUrl?: any }) => {
  return (
    <div className="noData">
      <img className={`${imgUrl ? `noDataImgLarge` : ``}`} src={imgUrl} alt="users" />
      <h5>{title}</h5>
      <p>{message}</p>
      {children && children}
    </div>
  );
};

export default NoDataPlaceHolder;
