import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { ROUTES, hasCustomAddOnFlag } from 'constants/constants';
import { AuthContext } from 'context/AuthContext';
import { AUTH_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { customScan, verifyUser } from 'services/authServices';
import { JwtTokenValidate, setLocalStorageData } from 'utils/utils';
import ShowSuccessMessage from 'component/showMessage/ShowSuccessMessage';
import Signup from 'pages/signup/Signup';

const UserVerify = () => {
  const { handleAuth, setUserDetails, setIsInternalUser, setHasCustomAddon } = useContext(AuthContext) as AuthContextInterface;
  const [loading, setLoading] = useState<boolean>(true);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [verificationFailed, setVerificationFailed] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  async function handleVerifyUser(token: string) {
    setLoading(true);
    try {
      const response = await verifyUser(token);
      if (response?.data?.internal) {
        setLocalStorageData('InternalJWTToken', response?.data?.token);
        setIsInternalUser(true);
        handleAuth(AUTH_TYPE.LOGIN);
        navigate(ROUTES.CUSTOMERS_LIST);
      } else if (!response?.data?.internal) {
        setLocalStorageData('JWTToken', response?.data?.token);
        const decodedToken = JwtTokenValidate(response?.data?.token);
        setUserDetails(decodedToken?.properties);
        try {
          const res = await customScan();
          setLocalStorageData(hasCustomAddOnFlag, res?.data?.hasCustomAddon);
          setHasCustomAddon(res?.data?.hasCustomAddon);
        } catch (err) {
          console.log(err);
        }
        handleAuth(AUTH_TYPE.LOGIN);
        navigate(ROUTES.HOME);
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const extractError = JSON.parse(err?.request?.response);
        if (!extractError?.appInstalled && extractError?.registered) {
          setErrorMessage(extractError?.message);
          setVerificationFailed(true);
        }
      } else {
        setVerificationFailed(true);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchParams.get('token') && searchParams.get('token') !== 'null') {
      handleVerifyUser(searchParams.get('token') ?? '');
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <>
      <div className="flex alignCenter justifyCenter userVerify w-full">
        {loading ? (
          <div className="flex alignCenter justifyCenter">
            <CircularProgress size={30} />
          </div>
        ) : (
          <div className="flex alignCenter justifyCenter w-full">
            {verificationFailed || registerSuccess ? (
              <ShowSuccessMessage registerSuccess={registerSuccess} verificationFailed={verificationFailed} errorMessage={errorMessage} />
            ) : (
              <Signup setRegisterSuccess={setRegisterSuccess} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserVerify;
