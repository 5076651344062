import React, { useEffect, useRef } from 'react';

const InfiniteScrollComponent = ({ functionToCall, data }: { functionToCall: () => void; data: any[] }) => {
  const observerTarget = useRef<HTMLDivElement>(null);
  const observer = useRef<any>(null);

  useEffect(() => {
    if (observer.current) {
      observer.current.unobserve(observerTarget.current);
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          try {
            functionToCall();
          } catch (error) {
            if (observerTarget.current) {
              observer.current.unobserve(observerTarget.current);
            }
            console.error('Error in functionToCall:', error);
          }
          if (observerTarget.current) {
            observer.current.unobserve(observerTarget.current); // Remove observer once triggered
          }
        }
      },
      { threshold: 0.9 }
    );

    if (observerTarget.current) {
      observer.current.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.current.unobserve(observerTarget.current);
      }
    };
  }, [data]);

  return <div ref={observerTarget} id="last-item" style={{ height: '5px' }}></div>;
};

export default InfiniteScrollComponent;
