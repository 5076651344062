import React from 'react';
import styles from './SubHeader.module.scss';
import refreshIcon from 'assets/images/refresh-icon.svg';
import { SHOW_CASE } from 'enum/enum';
import { GroupData } from 'interface/interface';
import TooltipBlock from 'component/TooltipBlock/TooltipBlock';

interface MeasurementSubHeader {
  showCase: SHOW_CASE;
  groups: GroupData[];
  handleProductsInitialize: () => Promise<void>;
  refreshLoading: boolean;
}
const SubHeader = ({ showCase, handleProductsInitialize, refreshLoading }: MeasurementSubHeader) => {
  return (
    <div className={`${styles.subheader}`}>
      <div className="flex justifySpaceBetween alignStart">
        <div>
          {(() => {
            switch (showCase) {
              case SHOW_CASE.AUTH: {
                return <h1>Let’s start your measurement onboarding.</h1>;
              }
              case SHOW_CASE.LOAD_PRODUCT: {
                return <h1>Let’s start your measurement onboarding.</h1>;
              }
              case SHOW_CASE.ALL_PRODUCTS: {
                return (
                  <>
                    <h1>
                      Product selection{' '}
                      <TooltipBlock holderContent={refreshLoading ? '' : 'Refresh to initialize'}>
                        <span onClick={handleProductsInitialize}>{<img className={`${refreshLoading && styles.rotating}`} src={refreshIcon} alt="icon" />}</span>
                      </TooltipBlock>
                    </h1>

                    <p>Below are all the products live on your site. Please toggle off those which you do not want supported by Swan.</p>
                    <p>Tip: if unsure which to support, remove the products for which you don’t have measurements for.</p>
                  </>
                );
              }
              case SHOW_CASE.FILE_UPLOAD_TYPE: {
                return (
                  <>
                    <h1>Measurement upload</h1>
                  </>
                );
              }
              case SHOW_CASE.REVIEW_TABLE: {
                return (
                  <>
                    <h1>Review</h1>
                    <p> Here are your parsed files. Add any missing details & push them live.</p>
                  </>
                );
              }
              default: {
                return '';
              }
            }
          })()}
        </div>
        {/* <img src={dot} alt="menu" role="cursor" /> */}
      </div>
    </div>
  );
};

export default SubHeader;
