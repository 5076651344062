import React from 'react';
import styles from './Pagination.module.scss';
import arrowLeft from '../../assets/images/arrow-left-gray.svg';
import arrowRight from '../../assets/images/arrow-right-gray.svg';

interface PaginationProps {
  pageSize?: number;
  pageNo?: number;
  setPageNo?: (a: number) => void;
  secondary?: boolean;
  data?: Record<string, any>[];
}
const Pagination = ({ secondary, pageNo, pageSize, setPageNo, data }: PaginationProps) => {
  return (
    <>
      {secondary ? (
        <>
          <div className={`dflex justifySpaceBetween alignCenter ${styles.pagination}`}>
            <div className={`${styles.pagination__prev}`}>
              <span>
                <img src={arrowLeft} alt="arrow" />
                Previous
              </span>{' '}
            </div>
            <div className={`${styles.pagination__next}`}>
              <span>
                Next
                <img src={arrowRight} alt="arrow" />
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`dflex justifySpaceBetween alignCenter ${styles.secondary}`}>
            <div className={`${styles.secondary__prev}`}>{pageNo && pageNo > 1 && <span onClick={() => pageNo && setPageNo && setPageNo(pageNo - 1)}>Previous</span>}</div>
            <div className={`dflex ${styles.secondary__no}`}>Page {pageNo ?? 1}</div>
            <div className={`${styles.secondary__next}`}>
              {pageSize && data && pageSize === data?.length && <span onClick={() => pageNo && setPageNo && setPageNo(pageNo + 1)}>Next</span>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Pagination;
