import React from 'react';
import styles from './ProductProgressGraph.module.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// import dot from 'assets/images/dots-vertical.svg';

interface ProductProgressGraphProps {
  productsSupported: any;
}
const ProductProgressGraph = ({ productsSupported }: ProductProgressGraphProps) => {
  return (
    <div className={`${styles.progressBarMain}`}>
      {/* <header className={` flex justifySpaceBetween alignCenter`}>
        <div>
          <h2>SWAN Onboarded Products</h2>
          <p>You’re using {productsSupported?.percentage ?? 0}% of free Swan powered sales</p>
        </div>
        <figure>
          <img src={dot} alt="menu" role="cursor" />
        </figure>
      </header> */}
      <div className={`flex justifyCenter alignCenter ${styles.progressCircle}`}>
        <CircularProgressbar
          value={productsSupported?.percentage ?? 0}
          text={`${productsSupported?.onboardedProductsCount ?? 0}`}
          styles={buildStyles({
            // Text size
            textSize: '30px',

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `#5932F3`,
            textColor: '#101828',
            trailColor: '#EAECF0',
            backgroundColor: '#fff',
          })}
        />
      </div>
    </div>
  );
};

export default ProductProgressGraph;
