import { API_END_POINTS, SOMETHING_WENT_WRONG } from 'constants/constants';
import { TOKEN_DATA_TYPE, URL_TYPE } from 'enum/enum';
import UseApiService from './apiService';
import { AxiosResponse } from 'axios';
import { ApiDataObject, TechPackData, onBoardedData } from 'interface/interface';
// import { handlePromiseArray } from 'utils/utils';

export const getAllProducts = (requestUrl = '', type = URL_TYPE.NEXT) => {
  let body: any = {
    url: API_END_POINTS.PRODUCTS,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  if (requestUrl) {
    body = { ...body, data: { [type]: requestUrl } };
  }
  return UseApiService().get(body);
};

export const onboardProduct = (data: onBoardedData[]) => {
  const body = {
    url: API_END_POINTS.ONBOARD_PRODUCT,
    data,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};
async function fetchAllCollections(body: ApiDataObject, collectionList: any[]): Promise<any[]> {
  try {
    const response: AxiosResponse = await UseApiService().get(body);
    const data = response?.data;
    collectionList.push(...data.collections);
    if (data?.pageInfo?.hasNextPage) {
      body = { ...body, data: { [URL_TYPE.NEXT]: data?.pageInfo?.endCursor } };
      return await fetchAllCollections(body, collectionList);
    } else {
      return collectionList;
    }
  } catch (error: any) {
    throw new Error(error?.response?.data?.error ?? SOMETHING_WENT_WRONG);
  }
}
export const getListCollections = (requestUrl = '', type = URL_TYPE.NEXT) => {
  let body: any = {
    url: API_END_POINTS.COLLECTIONS,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  if (requestUrl) {
    body = { ...body, data: { [type]: requestUrl } };
  }
  const collectionList: any[] = [];
  return fetchAllCollections(body, collectionList);
};

export const getCollectionsProduct = async (collectionId: string, requestUrl = '', type = URL_TYPE.NEXT) => {
  let body: any = {
    url: `${API_END_POINTS.COLLECTIONS}/${collectionId}${API_END_POINTS.PRODUCTS}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  if (requestUrl) {
    body = { ...body, data: { [type]: requestUrl } };
  }
  return UseApiService().get(body);
};

// async function fetchAllProducts(body: ApiDataObject, productList: any[]): Promise<any[]> {
//   try {
//     const response: AxiosResponse = await UseApiService().get(body);
//     const data = response?.data;
//     productList.push(...data.products);
//     if (data?.pageInfo?.hasNextPage) {
//       body = { ...body, data: { [URL_TYPE.NEXT]: data?.pageInfo?.endCursor } };
//       return await fetchAllProducts(body, productList);
//     } else {
//       return productList;
//     }
//   } catch (error: any) {
//     throw new Error(error?.response?.data?.error ?? SOMETHING_WENT_WRONG);
//   }
// }

// export const getCollectionsProduct = async (collectionId: any[]) => {
//   const body = {
//     url: `${API_END_POINTS.COLLECTIONS}/${collectionId}${API_END_POINTS.PRODUCTS}`,
//     headerToken: TOKEN_DATA_TYPE.ACCESS,
//   };
//   const productList: any[] = [];
//   try {
//     return await fetchAllProducts(body, productList);
//   } catch (error) {
//     console.log(error, 'error in collection product ');
//     return [];
//   }
// };

// export const getAllProductsOfCollections = async (collections: any[]) => {
//   const promiseArray = [];
//   for (const collection of collections) {
//     const body = {
//       url: `${API_END_POINTS.COLLECTIONS}/${collection?.collectionId}${API_END_POINTS.PRODUCTS}`,
//       headerToken: TOKEN_DATA_TYPE.ACCESS,
//     };
//     promiseArray.push(fetchAllProducts(body, []));
//   }
//   const res = await handlePromiseArray(promiseArray);
//   const arrayOfAllProducts = res?.reduce((ac: any[][], result: any) => {
//     if (result?.status === FULFILLED) {
//       // eslint-disable-next-line no-debugger
//       ac.push(result?.value);
//     } else {
//       ac.push([]);
//     }
//     return ac;
//   }, []);
//   return arrayOfAllProducts;
// };
export const getTechPackData = (groupId: string) => {
  const body = {
    url: `${API_END_POINTS.TECHPACK}/${groupId}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};
export const submitTechPackData = async (techPackData: TechPackData) => {
  const data = {
    name: techPackData?.name,
    id: techPackData?.id,
    division: techPackData?.division,
    category: techPackData?.category,
    description: techPackData?.description?.trim() ?? '',
    base_size: techPackData?.base_size,
    sizes: techPackData?.sizes,
    ease: techPackData?.ease,
  };
  const body = {
    url: `${API_END_POINTS.TECHPACK}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
    data,
  };
  return UseApiService().post(body);
};
