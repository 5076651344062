import React, { useContext, useEffect, useState } from 'react';
import styles from './Custom.module.scss';

import { ToastTypes } from 'enum/enum';
import CustomNameDetails from 'component/customNameDetails/CustomNameDetails';
import CustomGroup from 'component/customGroup/CustomGroup';
import { customScan } from 'services/authServices';
import CircularLoader from 'component/CircularLoader';
import { customMeasurementsUpdate, customUploadNotes } from 'services/fileUploadServices';
import { ToastContext } from 'context/Toast';
import { ToastInterface } from 'interface/interface';
import { handleErrorMessage } from 'utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/constants';
import topHeader from '../../assets/images/custom-header.png';
let prevText = '';
const Custom = () => {
  const [selectedValue, setSelectedValue] = useState<Record<string, any>>({});
  const [customFitData, setCustomFitData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const [noteSubmitLoading, setNoteSubmitLoading] = useState<boolean>(false);
  const [noteEditable, setNoteEditable] = useState<boolean>(false);
  const [measurementEditable, setMeasurementEditable] = useState<boolean>(false);
  const [measurementsSubmitLoading, setMeasurementsSubmitLoading] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSelectedCustom = (value: Record<string, any>) => {
    setSelectedValue(value);
    prevText = value?.notes?.notes;
    navigate(`details/${value?.id}`);
  };

  const handleMeasurementSubmit = async () => {
    setMeasurementsSubmitLoading(true);
    try {
      await customMeasurementsUpdate(selectedValue?.measurements, selectedValue?.id);
      setMeasurementEditable(false);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setMeasurementsSubmitLoading(false);
    }
  };

  // const handleFileUploaded = async (uploadFiles: File[]) => {
  //   setNoteSubmitLoading(true);
  //   const modFiles = uploadFiles.map((file: File) => {
  //     const id = uuidv4();
  //     return {
  //       file,
  //       id,
  //       uploaded: false,
  //       show: true,
  //       showProgress: true,
  //     };
  //   });

  //   setFiles((prev) => [...prev, ...modFiles]);
  //   try {
  //     const uploadMultiFileRes = await fileUploadUrlPromiseArray(modFiles, '', true, selectedValue?.id);
  //     setFiles((prev) => {
  //       uploadMultiFileRes?.forEach((el: FileUploadData) =>
  //         prev.forEach((file: FileUploadData) => {
  //           if (file?.id === el?.id) {
  //             file.uploaded = el?.uploaded;
  //             file.show = el?.uploaded;
  //             file.showCross = false;
  //             file.showDelete = true;
  //           }
  //         })
  //       );
  //       return [...prev];
  //     });
  //   } catch (error) {
  //     callToast(ToastTypes.ERROR, handleErrorMessage(error));
  //   } finally {
  //     setNoteSubmitLoading(false);
  //   }
  // };

  const handleNotes = async (): Promise<void> => {
    setNoteSubmitLoading(true);
    try {
      await customUploadNotes(selectedValue?.notes?.notes, selectedValue?.id);
    } catch (error) {
      setSelectedValue((prev: any) => {
        return { ...prev, notes: { notes: prevText } };
      });
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setNoteSubmitLoading(false);
      prevText = '';
      setNoteEditable(false);
    }
  };
  const getCustomScans = async () => {
    setLoading(true);
    try {
      const res = await customScan(pageNo, pageSize);
      setCustomFitData(res?.data);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  // const allUploadedFiles = async (scanId: string) => {
  //   setSId(scanId);
  //   setFileLoading(true);
  //   try {
  //     const filesRes = await getUploadedFiles(scanId);
  //     const modFileRes = filesRes?.data?.files?.map((el: any) => {
  //       const id = uuidv4();
  //       return {
  //         file: { name: el?.file, size: el?.size },
  //         id,
  //         uploaded: true,
  //         show: true,
  //         showProgress: false,
  //         showUploaded: false,
  //         showCross: false,
  //         showDelete: true,
  //       };
  //     });
  //     if (!modFileRes?.length) {
  //       return;
  //     }
  //     setFiles([...modFileRes]);
  //   } catch (error) {
  //     callToast(ToastTypes.ERROR, handleErrorMessage(error));
  //   } finally {
  //     setFileLoading(false);
  //   }
  // };
  // const handleDeleteFile = async (fileName: string, id: string) => {
  //   const oldFiles = [...files];
  //   const index = oldFiles?.findIndex((el) => el?.id === id);
  //   oldFiles[index].showDelete = false;
  //   oldFiles[index].showProgress = true;
  //   oldFiles[index].uploaded = false;
  //   oldFiles[index].value = 0;
  //   setFiles([...files]);
  //   try {
  //     await deleteFile(fileName, sId);
  //     callToast(ToastTypes.SUCCESS, 'File Deleted Successfully');
  //     const filteredFile = oldFiles?.filter((el: any) => el?.id !== id);
  //     setFiles(filteredFile);
  //   } catch (error) {
  //     oldFiles[index].showDelete = true;
  //     oldFiles[index].showProgress = false;
  //     oldFiles[index].uploaded = true;
  //     setFiles([...oldFiles]);
  //     callToast(ToastTypes.ERROR, handleErrorMessage(error));
  //   }
  // };

  useEffect(() => {
    if (Object.keys(selectedValue).length === 0) {
      navigate(ROUTES.CUSTOM);
    }
  }, []);
  useEffect(() => {
    getCustomScans();
  }, [pageNo]);
  return (
    <div className={`${styles.calibrationMain}`}>
      <div className={`${styles.topGradient}`}>
        <img src={topHeader} alt="header" />
      </div>
      {/* <CustomModal customClass="welcomeDialog" showModal={calibrationModal} handleShowModal={() => setCalibrationModal(false)}>
        <div className={`${styles.welcomeDialog}`}>
          <div className={`${styles.welcomeDialog__img}`}>
            <img src={welcome} alt="welcome" />
          </div>
          <div className={`${styles.welcomeDialog__info}`}>
            <h2>Welcome to Swan Custom</h2>
            <p>First, you need ten people to scan and for you to upload their manual measurements.</p>
            <p>This kicks off a short calibration.</p>
          </div>
          {<CustomButton className="button full lg" buttonText={'Next'} handleFunc={() => setCalibrationModal(false)} />}
        </div>
      </CustomModal> */}
      {loading && (
        <div className="w-full flex alignCenter justifyCenter">
          <CircularLoader />
        </div>
      )}

      {!loading && (
        <>
          {pathname.includes('custom/details') ? (
            <CustomNameDetails
              selectedValue={selectedValue}
              handleNotes={handleNotes}
              setNoteEditable={setNoteEditable}
              noteEditable={noteEditable}
              setSelectedValue={setSelectedValue}
              noteSubmitLoading={noteSubmitLoading}
              setMeasurementEditable={setMeasurementEditable}
              measurementEditable={measurementEditable}
              handleMeasurementSubmit={handleMeasurementSubmit}
              measurementsSubmitLoading={measurementsSubmitLoading}
              getCustomScans={getCustomScans}
            />
          ) : (
            <div className={`${styles.cardMain}`}>
              <div className={`${styles.cardList}`}>
                <div className={`${styles.cardLists}`}>
                  <div className={styles.card}>
                    <CustomGroup handleSelectedName={handleSelectedCustom} customFitData={customFitData} setPageNo={setPageNo} pageNo={pageNo} pageSize={pageSize} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* <CustomModal showModal={showModal} handleShowModal={handleShowModal} customClass="measurementDialog">
        <div className={`measurementForm ${styles.customDialog} ${fileLoading ? styles.customDialogLoader : ``} `}>
          <div className={`flex justifySpaceBetween alignStart ${styles.customDialog__header}`}>
            <div>
              <h2>Update manual measurement</h2>
              <p>for {selectedValue?.profile?.userEmail}</p>
            </div>

            <span role="cursor" onClick={handleShowModal}>
              <img src={cross} alt="cross" />
            </span>
          </div>
          {fileLoading ? (
            <div className={` ${styles.formLoader}`}>
              {' '}
              <CircularLoader />
            </div>
          ) : (
            <div className={` ${styles.form}`}>
              <div className={`flex alignCenter justifySpaceBetween ${styles.excelBox}`}>
                <div className="flex alignCenter ">
                  <figure>
                    <img src={sheet} alt="icon" />
                  </figure>
                  <figcaption>
                    <h5>Google Sheets</h5>
                    <p>Standard template</p>
                  </figcaption>
                </div>
                <CustomButton buttonText="Download" className={'outline button gray'} handleFunc={handleDownloadBtn} />
              </div>
              <br />
              <TextArea value={notes} name="notes" placeholder="Comment on the missing info" onChange={(e) => setNotes(e.target.value)} />
              <div className={` ${styles.upload}`}>
                <FileUpload handleFileUploaded={handleFileUploaded} />
                {files?.map((file: any) => (
                  <Files data={file} key={file.id} handleDeleteFile={handleDeleteFile} sId={sId} />
                ))}
              </div>
              <div className={` ${styles.dialogFooter} dflex`}>
                <div className={` ${styles.dialogFooter__half}`}>
                  <CustomButton disabled={noteSubmitLoading} handleFunc={handleShowModal} buttonText={'Cancel'} className="button gray outline full lg" />
                </div>
                <div className={` ${styles.dialogFooter__half}`}>
                  <CustomButton disabled={noteSubmitLoading} handleFunc={handleShowModal} buttonText={'Submit'} className="button full lg" />
                </div>
              </div>
            </div>
          )}
        </div>
      </CustomModal> */}
    </div>
  );
};

export default Custom;
