import axios from 'axios';
import { AUTH_TYPE, TOKEN_DATA_TYPE } from 'enum/enum';
import { getLocalStorageData } from 'utils/utils';

const interceptor = (handleAuth: (type: AUTH_TYPE) => void) => {
  axios.interceptors.request.use(
    (req: any) => {
      const headerToken = req?.headers?.headerToken;
      if (headerToken === TOKEN_DATA_TYPE.ACCESS) {
        const token = getLocalStorageData('JWTToken');
        req.headers = req.headers !== null ? { ...req.headers, Authorization: '' } : { Authorization: '' };
        req.headers.Authorization = `Bearer ${token}`;
      } else if (headerToken === TOKEN_DATA_TYPE.INTERNAL) {
        const token = getLocalStorageData('InternalJWTToken');
        req.headers = req.headers !== null ? { ...req.headers, Authorization: '' } : { Authorization: '' };
        req.headers.Authorization = `Bearer ${token}`;
      }
      return req;
    },
    (error: any) => {
      void Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    (error: any) => {
      console.log(error);

      if (error?.response?.status === 403) {
        error.response.data.message = '';
        handleAuth(AUTH_TYPE.LOGOUT);
      }
      return Promise.reject(error);
    }
  );
};
export default interceptor;
