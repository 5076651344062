import { Checkbox, SelectChangeEvent } from '@mui/material';
import CustomButton from 'component/CustomButton';
import styles from './ReviewTable.module.scss';
import check from 'assets/images/check-green.svg';
import React, { ChangeEvent } from 'react';
import { TechPackData } from 'interface/interface';
import TextArea from 'component/textarea/Textarea';
import CustomDropdown from 'component/customDropdown/CustomDropdown';
import { NONE, groupCategory, groupDivision } from 'constants/constants';
import { TechPackKeys } from 'enum/enum';
import TooltipBlock from 'component/TooltipBlock/TooltipBlock';

interface ReviewTableDescriptionProps {
  handleBaseSize: (e: SelectChangeEvent<any> | ChangeEvent<HTMLTextAreaElement>, t: TechPackKeys) => void;
  techPackRes: TechPackData;
  showOptions: boolean;
  handleShowOptions: () => void;
  rowOptions: Record<string, any>[];
  handleSelectedRow: (a: string, b: boolean) => void;
}

const ReviewTableDescription = ({ handleBaseSize, techPackRes, showOptions, handleShowOptions, rowOptions, handleSelectedRow }: ReviewTableDescriptionProps) => {
  return (
    <>
      <div className={` ${styles.review__header}`}>
        {/* <h2>
          {techPackRes?.name}
          <span>16 products</span>
        </h2> */}
        <p>Just one missing piece before you can go live</p>
        <div>
          <div className={styles.review__select}>
            <div className={`${styles.review__wrap} dflex`}>
              <div className={styles.review__item}>
                <p>Base size</p>
                <div className="select-wrap">
                  <CustomDropdown
                    value={techPackRes?.base_size ?? NONE}
                    optionList={Object.keys(techPackRes?.sizes)}
                    text="Select base size"
                    handleOnChange={(e: SelectChangeEvent<any>) => handleBaseSize(e, TechPackKeys.BASE_SIZE)}
                  />
                </div>
              </div>
              <div className={styles.review__item}>
                <p>Category</p>
                <div className="select-wrap">
                  <CustomDropdown
                    value={techPackRes?.category ?? NONE}
                    optionList={groupCategory}
                    text="Select category"
                    handleOnChange={(e: SelectChangeEvent<any>) => handleBaseSize(e, TechPackKeys.CATEGORY)}
                  />
                </div>
              </div>

              <div className={styles.review__item}>
                <p>Division</p>
                <div className="select-wrap">
                  <CustomDropdown
                    value={techPackRes?.division ?? NONE}
                    optionList={groupDivision}
                    text="Select division"
                    handleOnChange={(e: SelectChangeEvent<any>) => handleBaseSize(e, TechPackKeys.DIVISION)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.review__textBox}>
          <TextArea label="Description" value={techPackRes?.description ?? ''} placeholder="Provide description" onChange={(e) => handleBaseSize(e, TechPackKeys.DESCRIPTION)} />
        </div>
      </div>
      <div className={`dflex justifySpaceBetween ${styles.review__info}`}>
        <div className={styles.review__checkOuter}>
          <div className={`${styles.colSec}`}>
            <div className={`${styles.col}`}>
              <div className={`${styles.check}`}>
                <img src={check} alt="check" /> Checked
              </div>
              <h5>Garment measurements</h5>
            </div>
            <div className={`${styles.col}`}>
              <div className={`${styles.check}`}>
                <img src={check} alt="check" /> Checked
              </div>
              <h5>Base size worn by fit model</h5>
            </div>
            <div className={`${styles.col}`}>
              <div className={`${styles.missing}`}>Missing</div>
              <h5>Fit model measurements</h5>
            </div>
          </div>

          {showOptions ? (
            <div>
              <div className={styles.review__checklist}>
                {rowOptions?.map(
                  (el: Record<string, any>) =>
                    !el?.selected && (
                      <p key={el?.id}>
                        <Checkbox onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectedRow(el?.name, e.target.checked)} />
                        {el?.name}
                      </p>
                    )
                )}
              </div>
              <CustomButton buttonText="Save" className="button" handleFunc={handleShowOptions} />
            </div>
          ) : (
            <TooltipBlock holderContent={!techPackRes?.base_size ? 'Select base size first' : null}>
              <CustomButton buttonText="Add row" className={'button'} handleFunc={handleShowOptions} disabled={!techPackRes?.base_size} />
            </TooltipBlock>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewTableDescription;
