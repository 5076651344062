import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Dashboard from 'pages/dashboard/Dashboard';
import Login from 'pages/login/Login';
import Users from 'pages/users/Users';
import DesignWrapper from 'wrapper/DesignWrapper';
import App from 'App';
import Reporting from 'pages/reporting/Reporting';
import Supports from 'pages/supports/Supports';
import Settings from 'pages/settings/Settings';
import { hasCustomAddOnFlag, ROUTES } from 'constants/constants';
import Measurements from 'pages/measurements/Measurements';
import LoggedOutWrapper from 'wrapper/LoggedOutWrapper';
import LoggedInWrapper from 'wrapper/LoggedInWrapper';
import UserVerify from 'pages/userVerify/UserVerify';
import List from 'pages/analytic/List';
import Referrals from 'pages/referrals/Referrals';
import CustomerList from 'pages/customerList/CustomerList';
import Resources from 'pages/resources/Resources';
import Custom from 'pages/custom/Custom';
import { getLocalStorageData } from 'utils/utils';
import Analytics from 'pages/kpiAnalytic/Analytics';
function ProjectRoutes() {
  const router = createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <App />,
      children: [
        {
          path: ROUTES.HOME,
          element: (
            <LoggedInWrapper>
              <Navigate to={getLocalStorageData(hasCustomAddOnFlag) ? ROUTES.CUSTOM : ROUTES.ANALYTICS} replace />,
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.LOGIN,
          element: (
            <LoggedOutWrapper>
              <Login />
            </LoggedOutWrapper>
          ),
        },
        {
          path: ROUTES.NOT_MATCH,
          element: (
            <LoggedInWrapper>
              <Navigate to={getLocalStorageData(hasCustomAddOnFlag) ? ROUTES.CUSTOM : ROUTES.ANALYTICS} replace />,
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.DASHBOARD,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Dashboard />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },

        {
          path: ROUTES.MEASUREMENTS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Measurements />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.CUSTOM,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Custom />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
          children: [
            {
              path: ROUTES.CUSTOM_DETAILS,
              element: (
                <LoggedInWrapper>
                  <DesignWrapper>
                    <Custom />
                  </DesignWrapper>
                </LoggedInWrapper>
              ),
            },
          ],
        },

        {
          path: ROUTES.REPORTING,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Reporting />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.USERS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Users />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.RESOURCES,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Resources />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.SUPPORT,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Supports />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.SETTINGS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Settings />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.ANALYTICS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <List />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.KPI_ANALYTICS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Analytics />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.REFERRALS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Referrals />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        { path: ROUTES.CUSTOMERS_LIST, element: <CustomerList /> },
        {
          path: ROUTES.USER_VERIFY,
          element: <UserVerify />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default ProjectRoutes;
