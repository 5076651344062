import * as React from 'react';
import CustomButton from 'component/CustomButton';
import styles from './FileUploadModal.module.scss';
import close from 'assets/images/x-close-gray.svg';
import FileUpload from 'component/fileUpload/FileUpload';
import { Checkbox, FormControlLabel } from '@mui/material';
import FileUploaded from 'component/fileUploaded/FileUploaded';
import { ApprovedNotes, FileUploadData } from 'interface/interface';
import { ChangeEvent } from 'react';
import TextArea from 'component/textarea/Textarea';

interface FileUploadModalProps {
  handleShowModal: () => void;
  handledFileUploaded: (file: File[]) => void;
  files: FileUploadData[];
  fileFilter?: (a: string) => void;
  approvedNotes: ApprovedNotes;
  setApprovedNotes: (a: ApprovedNotes) => void;
  uploadMultipleFiles: () => void;
  loading: boolean;
}

export default function FileUploadModal({
  handleShowModal,
  handledFileUploaded,
  files,
  fileFilter,
  approvedNotes,
  setApprovedNotes,
  uploadMultipleFiles,
  loading,
}: FileUploadModalProps) {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any) => {
    const { name, value, checked } = event.target;

    if (name === 'notes') {
      setApprovedNotes({ ...approvedNotes, [name]: value });
    } else {
      setApprovedNotes({ ...approvedNotes, [name]: checked });
    }
  };
  return (
    <div>
      <div className={` ${styles.infoMain}`}>
        <header className="flex justifySpaceBetween">
          <h2>Add missing info</h2>
          <img onClick={handleShowModal} src={close} alt="close" />
        </header>
        <div className={` ${styles.infoContent}`}>
          <div className={` ${styles.infoUpload}`}>
            <FileUpload handleFileUploaded={handledFileUploaded} />
          </div>
          {files?.length > 0 && (
            <div className={`${styles.fileUploadedSec}`}>
              <div className={`${styles.upFileList} `}>
                <FileUploaded files={files} fileFilter={fileFilter} />
              </div>
            </div>
          )}
          <div className={` ${styles.infoForm}`}>
            <div className={` ${styles.textarea}`}>
              <label>Comment for Swan</label>
              <TextArea value={approvedNotes.notes} name="notes" placeholder="Comment on the missing info" onChange={handleOnChange} />
            </div>
            <div className={` ${styles.checkbox}`}>
              <FormControlLabel
                control={<Checkbox name="approved" checked={approvedNotes.approved} onChange={handleOnChange} />}
                label="I approve this to go live when issue is resolved"
              />
            </div>
            <div className={` ${styles.submit}`}>
              <CustomButton buttonText="Submit" className={'button'} handleFunc={uploadMultipleFiles} disabled={loading || !files?.length} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
