import { Dialog } from '@mui/material';
import React from 'react';
import close from '../assets/images/x-close-gray.svg';

interface ModalTableProps {
  setShowModalTable: any;
  open: boolean;
  image: any;
  title: string;
  desc: string;
}

const ModalTable = ({ setShowModalTable, open, image, title, desc }: ModalTableProps) => {
  const handleCloseModal = () => {
    setShowModalTable(false);
  };
  return (
    <Dialog open={open} className="tableModalPaper">
      <span className="close-modal" onClick={handleCloseModal}>
        <img src={close} alt="icon" />
      </span>
      <div className="tableModal">
        <div className="tableModal__image">
          <img src={image} alt="image" />
        </div>
      </div>
      <div className="tableModal__info">
        <h2>{title}</h2>
        <h3>We take:</h3>
        <p>{desc}</p>
      </div>
      <div className="tableModal__footer">
        <p>
          Do you take yours differently? <span>Let us know!</span>
        </p>
      </div>
    </Dialog>
  );
};

export default ModalTable;
