import TableCompoWithoutCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithoutCheckBox';
import React, { useEffect, useState } from 'react';
import styles from './CustomNameDetails.module.scss';
import { reviewHeadCells } from 'component/table/tableCompoWithCheckBox/tableData';
// impor CustomProductCard from 'component/customProductCard/CustomProductCard';
import edit from 'assets/images/edit.svg';
// import aiImage from '../../assets/images/ai.png';
import CustomButton from 'component/CustomButton';
import { v4 as uuidv4 } from 'uuid';
import TextArea from 'component/textarea/Textarea';
import axios from 'axios';
import { REACT_APP_PUBLIC_MODEL_VIEW_URL } from 'constants/constants';
import Model from 'component/model/Model';
import CircularLoader from 'component/CircularLoader';

interface CustomNameDetailRightProps {
  showMore: boolean;
  setShowMore: (a: boolean) => void;
  selectedValue: Record<string, any>;
  handleNotes: () => void;
  setNoteEditable: (a: boolean) => void;
  noteEditable: boolean;
  setSelectedValue: (a: Record<string, any>) => void;
  noteSubmitLoading: boolean;
  setMeasurementEditable: (a: boolean) => void;
  measurementEditable: boolean;
  handleMeasurementSubmit: () => void;
  measurementsSubmitLoading: boolean;
}
const customMeasurementValue = (data: Record<string, any>) => {
  delete data['isMeasured'];
  const array = Object.keys(data) ?? [];
  const length = Math.floor(array?.length / 2);
  const firstData = new Array(length);
  const secondData = new Array(array?.length - length);
  array?.forEach((el, index) => {
    const id = uuidv4();
    const value = {
      id,
      name: el,
      value: data[el],
    };
    if (index % 2 === 0) {
      firstData.push(value);
    } else {
      secondData.push(value);
    }
  });

  return [firstData, secondData];
};
const CustomNameDetailRight = ({
  showMore,
  setShowMore,
  selectedValue,
  handleNotes,
  setNoteEditable,
  noteEditable,
  setSelectedValue,
  noteSubmitLoading,
  setMeasurementEditable,
  measurementEditable,
  handleMeasurementSubmit,
  measurementsSubmitLoading,
}: CustomNameDetailRightProps) => {
  const [firstMeasurementsData, setFirstMeasurementsData] = useState<{ id: string; name: string; value: any }[]>([]);
  const [secondMeasurementsData, setSecondMeasurementsData] = useState<{ id: string; name: string; value: any }[]>([]);
  const [modelUrl, setModelUrl] = useState('');

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, row: string) => {
    const value = +e.target.value;
    if (value < 0) {
      return;
    }
    const a = selectedValue;
    a.measurements[row] = value;
    setSelectedValue({ ...a });
    let index = firstMeasurementsData?.findIndex((el: { id: string; name: string; value: any }) => el?.name === row);
    if (index === -1) {
      index = secondMeasurementsData?.findIndex((el: { id: string; name: string; value: any }) => el?.name === row);
      secondMeasurementsData[index].value = value;
      setSecondMeasurementsData([...secondMeasurementsData]);
    } else {
      firstMeasurementsData[index].value = value;
      setFirstMeasurementsData([...firstMeasurementsData]);
    }
  };
  const getModelForScanId = async () => {
    try {
      const res = await axios.get(`${REACT_APP_PUBLIC_MODEL_VIEW_URL ?? 'https://api.getswan.co'}/model/${selectedValue?.id}`);
      setModelUrl(res?.data?.modelUrl.url);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const [first, second] = customMeasurementValue(selectedValue?.measurements);
    setFirstMeasurementsData(first);
    setSecondMeasurementsData(second);
    getModelForScanId();
  }, []);
  return (
    <div className={` ${styles.note}`}>
      {/* <div className={` ${styles.boxBorder}`}>
        <div className={` ${styles.boxBorder__header}`}>
          <h2>Orders</h2>
          <p>View which products were ordered with this scan.</p>
        </div>
        <div className={` ${styles.boxBorder__wrapper}`}>
          <CustomProductCard />
        </div>
      </div> */}
      <div className={` ${styles.boxBorder}`}>
        <div className={` ${styles.boxBorder__header}`}>
          <h2>AI Mesh </h2>
          <p>Learn more about your customer’s shape & stance. Use your cursor or keys to move it around. </p>
        </div>
        <div className={` ${styles.boxBorder__wrapper}`}>
          {/* <div className={` ${styles.aiBox}`}>
            <img src={aiImage} alt="ai" />
          </div> */}
          {modelUrl ? <Model src={modelUrl} /> : <CircularLoader size={20} />}
        </div>
      </div>
      <div className={` ${styles.boxBorder}`}>
        <div className={` ${styles.boxBorder__header}`}>
          <h2>Measurements</h2>
          <p>See your customer’s measurements. </p>
        </div>
        <div className={` ${styles.boxBorder__wrapper}`}>
          <div className={` ${styles.tableEdit} flex justifyEnd alignCenter`}>
            {measurementEditable ? (
              <CustomButton
                disabled={measurementsSubmitLoading}
                loading={measurementsSubmitLoading}
                className="button primary"
                buttonText="Save"
                handleFunc={handleMeasurementSubmit}
              />
            ) : (
              <span role="cursor" onClick={() => setMeasurementEditable(true)}>
                <img src={edit} />
              </span>
            )}
          </div>
          <div className={` ${styles.table} table-group tableCustom`}>
            <div className={` ${styles.table__inner}`}>
              {firstMeasurementsData?.length > 0 && (
                <TableCompoWithoutCheckBox
                  measurementEditable={measurementEditable}
                  reviewRows={firstMeasurementsData}
                  headCells={reviewHeadCells}
                  handleOnChange={handleOnChange}
                  gender={selectedValue?.profile?.gender}
                />
              )}
              {secondMeasurementsData?.length > 0 && (
                <TableCompoWithoutCheckBox
                  measurementEditable={measurementEditable}
                  reviewRows={secondMeasurementsData}
                  headCells={reviewHeadCells}
                  handleOnChange={handleOnChange}
                  gender={selectedValue?.profile?.gender}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={` ${styles.noteText}`}>
        <div className={` ${styles.boxBorder}`}>
          <div className={`flex justifySpaceBetween ${styles.boxBorder__header}`}>
            <div>
              <h2>Notes</h2>
              <p>Add notes about your customer and their preference.</p>
            </div>
            <div>
              {noteEditable ? (
                <CustomButton buttonText="save" className="button" handleFunc={handleNotes} disabled={noteSubmitLoading} loading={noteSubmitLoading} />
              ) : (
                <CustomButton buttonText={(selectedValue?.notes?.notes || '')?.length > 0 ? 'Edit' : 'Add'} className="button" handleFunc={() => setNoteEditable(true)} />
              )}
            </div>
          </div>
          <div className={` ${styles.noteEdit}`}>
            <h5>Notes</h5>
            {noteEditable ? (
              <TextArea
                autoFocus
                value={selectedValue?.notes?.notes ?? ''}
                onChange={(e) =>
                  setSelectedValue((prev: any) => {
                    return { ...prev, notes: { notes: e.target.value } };
                  })
                }
              />
            ) : showMore ? (
              <p>{selectedValue?.notes?.notes ?? ''}</p>
            ) : (
              <p>{(selectedValue?.notes?.notes ?? '').substr(0, 445)}</p>
            )}
            {(selectedValue?.notes?.notes ?? '').length > 445 && (
              <div className={` ${styles.readMore}`}>
                <a onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Read More'}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNameDetailRight;
