import TableCompoWithStartingCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithStartingCheckBox';
import React from 'react';
import edit from 'assets/images/edit.svg';
import style from './GroupCompo.module.scss';
import CircularLoader from 'component/CircularLoader';
import { ModalContentType } from 'enum/enum';
import RemoveIcon from '../../assets/images/delete.svg';
import CloseIcon from '../../assets/images/close.svg';
import InfiniteScrollComponent from 'component/InfiniteScrollComponent';
import TooltipBlock from 'component/TooltipBlock/TooltipBlock';
// import { Switch } from '@mui/material';
import { GroupCompoProps } from 'interface/interface';

const handleLongTitle = (text: string) => {
  if (text?.length > 200) {
    return text.substring(0, 200) + '...';
  }
  return text;
};
const GroupCompo = ({
  title,
  data,
  handleProductOnBoard,
  handleProductPageApi,
  loading,
  buttonDisabled,
  handleShowModal,
  selectedProduct,
  setSelectedProduct,
  handleRemoveProductsFromGroup,
  id,
}: GroupCompoProps) => {
  return (
    <div className={style.groupCompo}>
      <div className={`${style.groupCompo__header} dflex alignCenter justifySpaceBetween`}>
        <div className={`${style.groupCompo__action} flex alignCenter`}>
          <img src={edit} alt="edit" onClick={() => handleShowModal(ModalContentType.EDIT, id)} />

          <button className={style.groupCompo__actionBtn} onClick={() => handleShowModal(ModalContentType.DELETE, id)} disabled={buttonDisabled}>
            <img src={RemoveIcon} alt="" />
          </button>
          {selectedProduct?.length > 0 && (
            <button className={style.groupCompo__actionBtn} onClick={handleRemoveProductsFromGroup} disabled={buttonDisabled}>
              <img src={CloseIcon} alt="" />
            </button>
          )}
        </div>
        <h2 className={`${title?.toString()?.length > 200 ? style.isActive : ''} flex alignCenter`}>
          <TooltipBlock className="text" placement="top" holderContent={title?.toString()?.length > 200 ? title : ''}>
            <>{handleLongTitle(title.toString())}</>
          </TooltipBlock>
          {/* <Switch checked={allToggle} disabled={!data?.length} onChange={(e) => handleShowModal(ModalContentType.ONBOARD, id, e.target.checked)} /> */}
        </h2>
      </div>
      <div>
        {loading && data?.length === 0 ? (
          <div className="dflex alignCenter justifyCenter w-full">
            <CircularLoader height={false} />
          </div>
        ) : (
          <div className={style.groupCompo__table}>
            <TableCompoWithStartingCheckBox
              data={data}
              loading={loading}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              handleProductOnBoard={(value, id) => handleProductOnBoard(value, id)}
              handleShowModal={handleShowModal}
              showCheckBox={false}
              showToggle={false}
            />
            <InfiniteScrollComponent functionToCall={handleProductPageApi} data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupCompo;
