import React from 'react';
import styles from './FileUploaded.module.scss';
import 'react-circular-progressbar/dist/styles.css';
import { FileUploadData } from 'interface/interface';

import Files from 'component/files/Files';
// import film from '../../assets/images/film.svg';
// import cloud from '../../assets/images/upload-cloud-blue.svg';
interface FileUploadProps {
  files: FileUploadData[];
  fileFilter?: (a: string) => void;
}

const FileUploaded = ({ files, fileFilter }: FileUploadProps) => {
  return (
    <>
      <div className={`${styles.uploaded}`}>
        {files?.map((data: FileUploadData) => data?.show && <Files data={data} key={data?.id} fileFilter={fileFilter} />)}
        {/* <div className={`${styles.uploaded__box} flex`}>
          <figcaption className="flex">
            <figure>
              <img src={film} alt="file" />
            </figure>
            <figcaption>
              <h5>Black Dress</h5>
              <p>16 MB – 70% uploaded</p>
            </figcaption>
          </figcaption>
          <figure>
            <CircularProgressbar value={20} strokeWidth={12} />
            <img src={close} alt="close" />
          </figure>
        </div>
        <div className={`${styles.uploaded__box} flex`}>
          <figcaption className="flex">
            <figure>
              <img src={cloud} alt="file" />
            </figure>
            <figcaption>
              <h5>Raw denim jeans</h5>
              <p>4.2 MB – 70% uploaded</p>
            </figcaption>
          </figcaption>
          <figure>
            <CircularProgressbar value={20} strokeWidth={12} />
            <img src={close} alt="close" />
          </figure>
        </div> */}
      </div>
    </>
  );
};

export default FileUploaded;
